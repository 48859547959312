<template>
  <Field
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    :name="name"
    aria-describedby="input-true input-false input-help"
    aria-invalid="true"
    v-slot="{ meta, field }"
    :validate-on-input="false"
    :validate-on-change="false"
    :validate-on-blur="false"
  >
    <label
      :for="name"
      class="form-label">
      {{ label }}
    </label>

    <span
      v-if="floatTextRight"
      class="input-label-right">
      {{ floatTextRight }}
    </span>
    <input
      v-bind="field"
      :name="name"
      :value="modelValue"
      :id="name"
      :type="type"
      class="form-control"
      :class="{
            'vue-is-valid': meta.valid && meta.touched,
            'vue-is-invalid': !meta.valid && meta.touched,
          }"

      :placeholder="placeholder"
      :min="min"
      :max="max"
      :step="step"
    />
  </Field>
  <ErrorMessage as="div" :name="name" v-slot="{ message }" class="vue-invalid-feedback">
    {{ message }}
  </ErrorMessage>

  <div class="d-flex justify-content-between">
    <div id="inputQuantity" class="form-text">
      {{ bottomTextLeft }}
    </div>
    <div id="inputQuantity" class="form-text">
      {{ bottomTextRight }}
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate'
export default {
  name: 'SimpleInput',
  components: {
    Field,
    ErrorMessage
  },
  props: [
    'modelValue',
    'type',
    'label',
    'name',

    'placeholder',
    'min',
    'max',
    'step',
    'floatTextRight',
    'bottomTextLeft',
    'bottomTextRight'
  ]
}
</script>

<style scoped>
h3 {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-title);
}

.title-order {
  font-size: 18px;
}

h4 {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-default);
}

h5 {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-default);
}

h6 {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-title);
}

input[type="number"],
input[type="text"],
input[type="password"] {
  padding: 0.625rem 0.75rem;
}

select,
input,
textarea {
  font-size: 14px;
  font-weight: normal;
  border-radius: 7px;
  background-color: #f7f7f7;
  border-color: var(--border-default);
  color: var(--text-default);
}

.form-check-label {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-default);
}

input[type="checkbox"] {
  margin: unset;
}

textarea {
  min-height: 80px;
}

select:focus,
input:focus,
textarea:focus {
  font-size: 14px;
  font-weight: normal;
  border-radius: 6px;
  background-color: #f7f7f7;
  border-color: var(--border-default);
  color: var(--text-default);

  outline: unset;
  box-shadow: unset;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.form-label {
  font-size: 15px;
  font-weight: bold;
  color: var(--text-title);
}

.form-text {
  font-size: 11px;
  font-weight: normal;
  color: var(--text-default);
}

.form-label-small {
  font-size: 11px;
}

.position-sticky {
  top: 20px;
}

.input-label-right {
  position: absolute;
  right: 0;
  margin-top: 35px;
  padding: 8px 35px 8px 10px;
  color: var(--green-default);
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*.btn-custom {*/
/*  width: ;*/
/*}*/
.form-select-service {
  background-color: #fff;
  border-radius: 7px;
  border-color: var(--border-default);
}

.total {
  color: var(--text-title);
}

.funds {
  font-size: 14px;
  font-weight: bold;
  color: var(--green-default);
}

.btn-order {
  font-size: 22px;
  font-weight: bold;
  border-radius: 8px;
}
</style>
