import { createRouter, createWebHistory } from 'vue-router'
// const LoginView = () => import('../views/LoginView.vue')

import LoginView from '../views/Auth/LoginView.vue'
import RegisterView from '../views/Auth/RegisterView.vue'
import ForgetView from '@/views/Auth/ForgetView'
import ResendVerificationView from '@/views/ResendVerificationView'
import SettingsView from '../views/SettingsView.vue'
import ProfileView from '../views/ProfileView.vue'
import SubscriptionView from '../views/SubscriptionView.vue'
import NotificationView from '../views/NotificationView.vue'
import DashboardView from '../views/DashboardView.vue'
import OrdersView from '../views/OrdersView.vue'
import NewOrderView from '../views/NewOrderView.vue'
import FundsView from '../views/FundsView.vue'
import FundsHistoryView from '../views/FundsHistoryView.vue'
import TicketsView from '../views/TicketsView.vue'
import ShowTicketView from '../views/ShowTicketView.vue'
import NewTicketView from '../views/NewTicketView.vue'
import AffiliatesView from '../views/AffiliatesView.vue'
import TransactionView from '@/views/TransactionsView'
import ResetView from '@/views/Auth/ResetView'
import VerifyView from '@/views/Auth/VerifyView'
import { publicRoutes } from '@/config/globalMethods.config'
import LoginAsUserView from '@/views/Auth/LoginAsUserView'
import BillingView from '@/views/BillingView'
import AnnouncementsView from '@/views/AnnouncementsView'

const routes = [
    {
        path: '/register',
        name: 'register',
        component: RegisterView
    },
    {
        path: '/register/:id',
        name: 'register.referral',
        component: RegisterView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/login-as-user/:id/:token',
        name: 'login.as.user',
        component: LoginAsUserView
    },

    {
        path: '/forget',
        name: 'auth.forget',
        component: ForgetView
    },
    {
        path: '/auth/reset/:token',
        name: 'auth.reset',
        component: ResetView
    },
    {
        path: '/auth/verify/:token',
        name: 'auth.verify',
        component: VerifyView
    },

    {
        path: '/',
        name: 'dashboard.index',
        component: DashboardView
    },
    {
        path: '/orders',
        name: 'order.index',
        component: OrdersView
    },
    {
        path: '/new-order',
        name: 'order.store',
        component: NewOrderView
    },

    {
        path: '/tickets',
        name: 'ticket.index',
        component: TicketsView
    },
    {
        path: '/new-ticket',
        name: 'ticket.store',
        component: NewTicketView
    },
    {
        path: '/new-ticket/:uid',
        name: 'ticket.store.params',
        component: NewTicketView
    },
    {
        path: '/ticket/:id',
        name: 'ticket.show',
        component: ShowTicketView
    },
    {
        path: '/profile',
        name: 'user.show',
        component: ProfileView
    },
    {
        path: '/billing',
        name: 'user.billing',
        component: BillingView
    },
    {
        path: '/subscription',
        name: 'subscription.index',
        component: SubscriptionView
    },
    {
        path: '/funds',
        name: 'fund.index',
        component: FundsView
    },
    {
        path: '/transaction',
        name: 'transaction.index',
        component: TransactionView
    },

    {
        path: '/auth/verify/resend',
        name: 'Resend Verification',
        component: ResendVerificationView
    },
    {
        path: '/settings',
        name: 'Settings',
        component: SettingsView
    },
    {
        path: '/notification',
        name: 'Notification',
        component: NotificationView
    },
    {
        path: '/funds/history',
        name: 'Funds History',
        component: FundsHistoryView
    },
    {
        path: '/affiliates',
        name: 'Affiliates',
        component: AffiliatesView
    },
    {
        path: '/announcements',
        name: 'announcement.index',
        component: AnnouncementsView
    }
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active'
})

router.beforeEach((to, from, next) => {
    const authRequired = !publicRoutes.includes(to.name)
    const authNotRequired = publicRoutes.includes(to.name)
    const loggedIn = localStorage.getItem('user')

    if (to.name === 'login.as.user') {
        next()
    } else if (to.name === 'auth.verify') {
        next()
    } else if (authRequired && !loggedIn) {
        next('/login')
    } else if (authNotRequired && loggedIn) {
        next('/')
    } else {
        next()
    }
})

export default router
