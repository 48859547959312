export function centsToDollar (cents) {
  if (!cents) {
    return '$0'
  }
  return (parseInt(cents) / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  })
}

export function getErrorFromRequest (err) {
  let message = ''

  if (typeof err.response.data.errors !== 'undefined') {
    const errors = err.response.data.errors
    Object.keys(errors).forEach((errorKey) => {
      errors[errorKey].forEach((errVal, errIdx) => {
        message += (message === '') ? errVal : ', ' + errVal
      })
    })
  } else if (typeof err.response.data.message !== 'undefined') {
    message = err.response.data.message
  }

  return message
}

export const publicRoutes = ['login', 'login-as-user', 'register', 'register.referral', 'auth.verify', 'auth.forget', 'auth.reset']
