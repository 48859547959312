<template>
  <div class="row">
    <div
      v-for="item in data"
      :key="item.id"
      :class="getClass()">
      <div
        @click="$emit('module-click', item.id)"
        class="card border-0 mb-3 card-anchor">
        <div class="card-header bg-transparent border-0">
          {{ item.title }}
        </div>
        <div class="card-body">
          <h5 class="card-title">
            {{ item.value }}
          </h5>
          <p class="card-text d-flex align-items-center">
            {{ item.info }}
          </p>
        </div>
        <div class="card-icon">
          <svg
            v-if="item.svg === 'dashboardOrders'"
            xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none"
            stroke="#CCDDFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
            <path d="M6 2L3 6v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V6l-3-4H6zM3.8 6h16.4M16 10a4 4 0 1 1-8 0"/>
          </svg>
          <svg
            v-if="item.svg === 'dashboardFunds'"
            xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-receipt-2" width="44" height="44"
            viewBox="0 0 24 24" stroke-width="1.5" stroke="#CCDDFF" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2"/>
            <path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5"/>
          </svg>
          <svg
            v-if="item.svg === 'dashboardTickets'"
            xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-device-mobile-message" width="44"
            height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#CCDDFF" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M11 3h10v8h-3l-4 2v-2h-3z"/>
            <path d="M15 16v4a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1h2"/>
            <path d="M10 18v.01"/>
          </svg>
          <svg
            v-if="item.svg === 'dashboardMembership'"
            xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none"
            stroke="#CCDDFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
            <line x1="19" y1="5" x2="5" y2="19"></line>
            <circle cx="6.5" cy="6.5" r="2.5"></circle>
            <circle cx="17.5" cy="17.5" r="2.5"></circle>
          </svg>
          <svg
            v-if="item.svg === 'orderPending'"
            xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none" stroke="#CCDDFF"
            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
            <circle cx="12" cy="12" r="10"></circle>
            <polyline points="12 6 12 12 16 14"></polyline>
          </svg>
          <svg
            v-if="item.svg === 'orderCompleted'"
            xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none"
            stroke="#CCDDFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
            <path
              d="M18 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3H6a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3V6a3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 3 3 0 0 0-3-3z"></path>
          </svg>
          <svg
            v-if="item.svg === 'orderAll'"
            xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none"
            stroke="#CCDDFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
            <rect x="3" y="3" width="7" height="7"></rect>
            <rect x="14" y="3" width="7" height="7"></rect>
            <rect x="14" y="14" width="7" height="7"></rect>
            <rect x="3" y="14" width="7" height="7"></rect>
          </svg>
          <svg
            v-if="item.svg === 'affiliateUsers'"
            xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-affiliate" width="44"
            height="44"
            viewBox="0 0 24 24" stroke-width="1.5" stroke="#CCDDFF" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M5.931 6.936l1.275 4.249m5.607 5.609l4.251 1.275"/>
            <path d="M11.683 12.317l5.759 -5.759"/>
            <circle cx="5.5" cy="5.5" r="1.5"/>
            <circle cx="18.5" cy="5.5" r="1.5"/>
            <circle cx="18.5" cy="18.5" r="1.5"/>
            <circle cx="8.5" cy="15.5" r="4.5"/>
          </svg>
          <svg
            v-if="item.svg === 'affiliateCommission'"
            xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none" stroke="#CCDDFF"
            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
            <line x1="12" y1="1" x2="12" y2="23"></line>
            <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
          </svg>
          <svg
            v-if="item.svg === 'affiliateWithdrawn'"
            xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none"
            stroke="#CCDDFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
            <path d="M17 2.1l4 4-4 4"/>
            <path d="M3 12.2v-2a4 4 0 0 1 4-4h12.8M7 21.9l-4-4 4-4"/>
            <path d="M21 11.8v2a4 4 0 0 1-4 4H4.2"/>
          </svg>
          <svg
            v-if="item.svg === 'fundsTransactions'"
            xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none"
            stroke="#CCDDFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
            <path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/>
            <path d="M14 3v5h5M9 15h6"/>
          </svg>
          <svg
            v-if="item.svg === 'fundsAvailable'"
            xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-receipt-2" width="44" height="44"
            viewBox="0 0 24 24" stroke-width="1.5" stroke="#CCDDFF" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2"/>
            <path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5"/>
          </svg>
          <svg
            v-if="item.svg === 'fundsTotal'"
            xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none"
            stroke="#CCDDFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
            <line x1="4" y1="9" x2="20" y2="9"></line>
            <line x1="4" y1="15" x2="20" y2="15"></line>
            <line x1="10" y1="3" x2="8" y2="21"></line>
            <line x1="16" y1="3" x2="14" y2="21"></line>
          </svg>
          <svg
            v-if="item.svg === 'membershipSubscription'"
            xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none"
               stroke="#CCDDFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
            <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="8.5" cy="7" r="4"></circle>
            <line x1="20" y1="8" x2="20" y2="14"></line>
            <line x1="23" y1="11" x2="17" y2="11"></line>
          </svg>
          <svg
            v-if="item.svg === 'membershipSavings'"
            xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none"
               stroke="#CCDDFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
            <line x1="12" y1="1" x2="12" y2="23"></line>
            <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
          </svg>
          <svg
            v-if="item.svg === 'membershipJoined'"
            xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none"
               stroke="#CCDDFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
            <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="8.5" cy="7" r="4"></circle>
            <polyline points="17 11 19 13 23 9"></polyline>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatsModule',
  props: ['data'],
  methods: {
    getClass () {
      switch (this.data.length) {
        case 1:
          return 'col-12'
        case 3:
          return 'col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 col-xxl-4'
        case 4:
          return 'col-12 col-sm-6 col-md-12 col-lg-6 col-xl-3 col-xxl-3'
      }
    }
  }
}
</script>

<style scoped>

</style>
