<template>
  <h5 class="pb-3">Newsletter</h5>
  <div class="mb-3 form-check">
    <input type="checkbox" class="form-check-input" id="newsletterCheck">
    <label class="form-check-label" for="newsletterCheck">Subscribe to our Newsletter?</label>
  </div>

  <hr>

  <h5 class="pb-3">Email Notifications</h5>
  <div class="mb-3 form-check">
    <input type="checkbox" class="form-check-input" id="blogsCheck">
    <label class="form-check-label" for="blogsCheck">Blogs</label>
  </div>
  <div class="mb-3 form-check">
    <input type="checkbox" class="form-check-input" id="eventsCheck">
    <label class="form-check-label" for="eventsCheck">Events and Meet-ups</label>
  </div>
  <div class="mb-3 form-check">
    <input type="checkbox" class="form-check-input" id="announcementCheck">
    <label class="form-check-label" for="announcementCheck">Announcements</label>
  </div>

  <hr>
</template>

<script>
export default {
  name: 'NotifySettings'
}
</script>

<style scoped>
h5 {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-default);
}
.form-check-label {
  padding-left: 25px;
  font-size: 16px;
  font-weight: normal;
  color: var(--text-default);
}
hr {
  color: var(--border-default);
  opacity: 0.1;
  margin: 40px 0;
}
</style>
