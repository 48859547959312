<template>
    <div v-if="announcementFeature" class="top-bar-view">
        <nav class="navbar navbar-light bg-light">
            <span class="navbar-text m-auto p-0">
                {{ announcementFeature.title }}
                <a href="#" class="navbar-text m-auto p-0" data-bs-toggle="modal"
                    :data-bs-target="`#announcementFeature${announcementFeature.id}`">
                    more
                </a>
            </span>
        </nav>

        <!-- Modal -->
        <div class="modal fade" :id="`announcementFeature${announcementFeature.id}`" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <img class="modal-close" width="24" height="24" src="@/assets/ic-close.svg" data-bs-dismiss="modal"
                        aria-label="Close">
                    <h5 class="modal-title" id="staticBackdropLabel">{{ announcementFeature.title }}</h5>
                    <h5 class="modal-date" id="staticBackdropLabel">{{ timestampToDate(announcementFeature.created_at) }} -
                        {{ timestampToTime24(announcementFeature.created_at) }}</h5>
                    <div class="modal-body" v-html="announcementFeature.message">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopBar',
    methods: {
        /* Static global helpers */
        timestampToDate(timestamp) {
            return this.$timestampToDate(timestamp)
        },
        timestampToTime24(timestamp) {
            return this.$timestampToTime24(timestamp)
        }
    },
    computed: {
        announcementFeature() {
            return this.$store.state.featureAnnouncement
        }
    }
}
</script>

<style scoped>
.top-bar-view .navbar {
    background-color: var(--purple-default) !important;
    z-index: 100;
}

.top-bar-view .navbar-text {
    color: #fff !important;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 24px;
}

.modal-dialog {
    max-width: 640px;
}

.modal-close {
    margin: 16px 16px 22px 16px;
    cursor: pointer;
}

.modal-title {
    font-size: 16px;
    font-weight: bold;
    color: var(--text-title);
    margin: 0 42px 0px 42px;
}

.modal-date {
    font-size: 12px;
    font-weight: normal;
    color: var(--text-title);
    margin: 0 42px 16px 42px;
}

.modal-body {
    font-size: 14px;
    line-height: 28px;
    font-weight: normal;
    color: var(--text-default);
    margin: 0 42px 51px 42px;
    padding: unset;
}
</style>
