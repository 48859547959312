<template>
    <div class="new-ticket-view">
        <div class="row">
            <div class="col-12">
                <h1 class="view-name">Open Ticket</h1>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card border-0 mb-3">
                        <div class="card-body pt-20">
                            <div v-if="success.status" class="form-group mb-4">
                                <div class="alert alert-success" role="alert">
                                    {{ success.message }}
                                </div>
                            </div>

                            <div v-if="error.status" class="form-group mb-4">
                                <div class="alert alert-danger" role="alert">
                                    {{ error.message }}
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="subject" class="form-label">Subject</label>
                                <input v-model="input.subject.value" name="subject" id="subject" type="text"
                                    class="form-control vue-is-valid" placeholder="Title of the ticket">
                                <div v-if="input.subject.error" class="input-error">
                                    {{ input.subject.error }}
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-12 col-lg-4 mb-4 mb-lg-0">
                                    <label for="department_id" class="form-label">ID</label>
                                    <input v-model="input.department_id.value" name="department_id" id="department_id"
                                        type="text" class="form-control vue-is-valid"
                                        placeholder="Order Id, Transaction Id">
                                    <div v-if="input.department_id.error" class="input-error">
                                        {{ input.department_id.error }}
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4 mb-4 mb-lg-0">
                                    <label for="department" class="form-label">Department</label>
                                    <div id="department" class="select2">
                                        <div class="form-select" data-bs-toggle="dropdown" data-bs-display="static"
                                            aria-expanded="false">
                                            {{ getDepartmentById(input.department) ?
                                                getDepartmentById(input.department).name : 'Pick a department' }}
                                        </div>
                                        <ul class="dropdown-menu-profile dropdown-menu p-3">
                                            <li v-for="item in departments" :key="item.id" class="mb-2">
                                                <div @click="input.department = item.id"
                                                    class="dropdown-item text-decoration-none d-flex justify-content-start align-items-center align-content-center cursor-pointer"
                                                    aria-current="page">
                                                    <span class="ps-3">{{ item.name }}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <label for="priority" class="form-label">Priority</label>
                                    <div id="priority" class="select2">
                                        <div class="form-select" data-bs-toggle="dropdown" data-bs-display="static"
                                            aria-expanded="false">
                                            {{ getPriorityById(input.priority) ? getPriorityById(input.priority).name :
                                                'Pick a priority' }}
                                        </div>
                                        <ul class="dropdown-menu-profile dropdown-menu p-3">
                                            <li v-for="item in priorities" :key="item.id" class="mb-2">
                                                <div @click="input.priority = item.id"
                                                    class="dropdown-item text-decoration-none d-flex justify-content-start align-items-center align-content-center cursor-pointer"
                                                    aria-current="page">
                                                    <span class="ps-3">{{ item.name }}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="message" class="form-label">Message</label>
                                <textarea v-model="input.message.value" name="message" id="message"
                                    class="form-control vue-is-valid" placeholder="Type your message here.."
                                    spellcheck="false"></textarea>
                                <div v-if="input.message.error" class="input-error">
                                    {{ input.message.error }}
                                </div>
                            </div>

                            <div class="send-message">
                                <div class="d-flex justify-content-between align-items-center ms-3 mt-3 me-3">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <div @click="focusAttachment" class="cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                viewBox="0 0 24 24" fill="none" stroke="#495c77" stroke-width="1.5"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path
                                                    d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48">
                                                </path>
                                            </svg>
                                        </div>
                                        <input
                                            @change="(e) => { (e.target.files.length > 0) ? input.attachment = e.target.files[0] : input.attachment = null }"
                                            id="attachment" name="attachment" ref="attachment" type="file" accept="image/*"
                                            class="form-control" />
                                    </div>

                                    <button @click="submit" class="message-send text-green">
                                        <span v-show="loading.submit" class="spinner-border spinner-border-sm"></span>
                                        <span v-show="!loading.submit">Send</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleInput from '@/components/Form/SimpleInput'
import TextareaInput from '@/components/Form/TextareaInput'
import axios from 'axios'

export default {
    name: 'NewTicketView',
    components: {
        SimpleInput,
        TextareaInput
    },
    created() {
        if (this.$route.params.uid !== undefined) {
            this.input.department_id = this.$route.params.uid
        }
    },
    data() {
        return {
            loading: {
                render: true,
                submit: false
            },
            error: {
                status: false,
                message: null
            },
            success: {
                status: false,
                message: null
            },
            input: {
                subject: {
                    error: null,
                    value: ''
                },
                department: 1,
                department_id: {
                    error: null,
                    value: ''
                },
                priority: 2,
                message: {
                    error: null,
                    value: ''
                },
                attachment: null
            },
            departments: [
                {
                    id: 1,
                    name: 'General Support'
                },
                {
                    id: 2,
                    name: 'Billing Support'
                }
            ],
            priorities: [
                {
                    id: 1,
                    name: 'Low'
                },
                {
                    id: 2,
                    name: 'Medium'
                },
                {
                    id: 3,
                    name: 'High'
                }
            ]
        }
    },
    methods: {
        getDepartmentById(id) {
            return this.departments.find(el => {
                return parseInt(el.id) === parseInt(id)
            })
        },
        getPriorityById(id) {
            return this.priorities.find(el => {
                return parseInt(el.id) === parseInt(id)
            })
        },
        async submit() {
            this.loading.submit = true
            this.input.subject.error = null
            this.input.department_id.error = null
            this.input.message.error = null
            this.error.status = false
            this.error.message = null

            if (this.input.subject.value === '') {
                this.input.subject.error = 'Subject is a required field'
            }

            if (this.input.department_id.value === '') {
                this.input.department_id.error = 'Id is a required field'
            }

            if (this.input.message.value === '') {
                this.input.message.error = 'Message is a required field'
            }

            if (this.input.subject.value === '' || this.input.department_id.value === '' || this.input.message.value === '') {
                this.loading.submit = false

                return
            }

            if (this.input.attachment !== null && this.input.attachment.size > 2 * 1024 * 1024) {
                this.error.status = true
                this.error.message = 'The attachment must not be greater than 2 megabytes.'
                this.loading.submit = false

                return
            }

            try {
                await axios.post(process.env.VUE_APP_API_URL + 'v1/user/ticket', {
                    subject: this.input.subject.value,
                    department: this.input.department,
                    department_id: this.input.department_id.value,
                    priority: this.input.priority,
                    message: this.input.message.value,
                    attachment: this.input.attachment,
                }, {
                    headers: this.$authHeader(true)
                })


                this.input.subject.value = ''
                this.input.department = 1
                this.input.department_id.value = ''
                this.input.priority = 2
                this.input.message.value = ''
                this.input.attachment = null
                this.$refs.attachment.value = null
                this.success.status = true
                this.success.message = 'Ticket opened successfully'

                this.loading.submit = false


            } catch (e) {
                const err = e.response.data
                this.error.message = ''

                if (typeof err.errors !== 'undefined') {
                    const errors = err.errors
                    Object.keys(errors).forEach((errorKey) => {
                        errors[errorKey].forEach((errVal, errIdx) => {
                            this.error.message += (this.error.message === '') ? errVal : ', ' + errVal
                        })
                    })
                } else if (typeof err.message !== 'undefined') {
                    this.error.message = err.message
                }

                this.error.status = true
                this.loading.submit = false

                throw new Error(e)
            }
        }
    }
}
</script>

<style scoped>
.message-send {
    font-size: 14px;
    font-weight: bold;
    border: unset;
    background: unset;
}

h3 {
    font-size: 16px;
    font-weight: bold;
    color: var(--text-title);
}

input[type=file] {
    text-indent: -105px;
    border: unset;
}
</style>
