<template>
  <div
    v-if="loading"
    class="w-100 d-flex justify-content-center align-items-center">
    <span class="spinner-border spinner-border">
    </span>
  </div>
  <div v-else>
    <div v-if="currentUser.status === 'unVerified'">
      <h5>
        Please verify your account using instructions sent to your email, or <a @click.prevent="sendVerificationMail" href="">resend verification link</a>
      </h5>
    </div>
    <div v-if="currentUser.status === 'suspended'">
      <h5>
        Your account has been suspended, create a ticket to discuss further...
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerifyAuth',
  data () {
    return {
      loading: false
    }
  },
  methods: {
    sendVerificationMail () {
      this.loading = true

      this.$store.dispatch('auth/verify', { email: this.currentUser.email }).then(
        (res) => {
          this.loading = false
          console.log('mail sent')
        }
      )
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.state.auth.authenticated
    },
    currentUser () {
      return this.$store.state.user
    }
  }
}
</script>

<style scoped>

</style>
