<template>
  <div
    v-if="loading.created < loading.createRequests"
    class="main-loading">
    <div class="spinner-border" role="status">
    </div>
  </div>
  <div v-else>
    <TitleModule :title="$options.title" />
    <div class="vue-view">
      <div class="row">
        <div class="col-12">
          <div class="card border-0 mb-3">
            <div class="card-header card-header-custom bg-transparent border-0"></div>
            <div class="card-body">
              <div v-if="loading.created >= loading.createRequests && rows.length > 0 && !loading.index">
                <Datatable
                  :columns="columns">
                  <tbody>
                  <tr
                    v-for="(row, idx) in rows"
                    :key="idx">
                    <td>{{ row.id }}</td>
                    <td>
                      <a
                        href="#"
                        class="d-flex text-decoration-none pe-3 anchor anchor-announcement"
                        data-bs-toggle="modal"
                        :data-bs-target="`#announcement${row.id}`">
                        {{ row.title }}
                      </a>
                      <!-- Modal -->
                      <div class="modal fade" :id="`announcement${row.id}`" data-bs-keyboard="false" tabindex="-1"
                           aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                          <div class="modal-content">
                            <img
                              class="modal-close"
                              width="24"
                              height="24"
                              src="@/assets/ic-close.svg"
                              data-bs-dismiss="modal"
                              aria-label="Close">
                            <h5 class="modal-title" id="staticBackdropLabel">{{ row.title }}</h5>
                            <h5 class="modal-date" id="staticBackdropLabel">{{ timestampToDate(row.created_at) }} - {{ timestampToTime24(row.created_at) }}</h5>
                            <div class="modal-body" v-html="row.message">
                            </div>
                            <!--          <a href="#" class="btn-link">Read more</a>-->
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{{ timestampToDate(row.created_at) }}<br><span>{{ timestampToTime24(row.created_at) }}</span></td>
                  </tr>
                  </tbody>
                </Datatable>
              </div>
              <div
                v-if="loading.created >= loading.createRequests && rows.length <= 0 && !loading.index"
                class="d-flex justify-content-center align-items-center" style="min-height: 431px;">
                <h5>No data found</h5>
              </div>
              <div
                v-if="loading.created < loading.createRequests || (loading.created >= loading.createRequests && loading.index)"
                class="d-flex justify-content-center align-items-center" style="min-height: 431px;">
                <div class="spinner-border" role="status">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleModule from '@/components/Modules/TitleModule'
import Datatable from '@/components/Form/Datatable'
export default {
  title: 'Announcements',
  name: 'AnnouncementsView',
  components: { Datatable, TitleModule },
  created () {
    this.$store.dispatch('announcement/created')
  },
  data () {
    return {
      referralLinkCopied: false
    }
  },
  methods: {
    /* Static global helpers */
    centsToDollar (cents) {
      return this.$centsToDollar(cents)
    },
    timestampToDate (timestamp) {
      return this.$timestampToDate(timestamp)
    },
    timestampToTime24 (timestamp) {
      return this.$timestampToTime24(timestamp)
    }
  },
  computed: {
    loading () { return this.$store.state.announcement.loading },
    rows () { return this.$store.state.announcement.rows },
    columns () { return this.$store.state.announcement.columns }
  }
}
</script>

<style scoped>
.anchor-announcement {
  font-size: 14px;
  margin-bottom: 16px;
}

.modal-dialog {
  max-width: 640px;
}

.modal-close {
  margin: 16px 16px 22px 16px;
  cursor: pointer;
}

.modal-title {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-title);
  margin: 0 42px 0px 42px;
}

.modal-date {
  font-size: 12px;
  font-weight: normal;
  color: var(--text-title);
  margin: 0 42px 16px 42px;
}

.modal-body {
  font-size: 14px;
  line-height: 28px;
  font-weight: normal;
  color: var(--text-default);
  margin: 0 42px 51px 42px;
  padding: unset;
}
</style>
