<template>
    <div class="vue-modal modal fade" id="checkoutModal" tabindex="-1" aria-labelledby="checkoutModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header p-3 border-0">
                    <h5 class="modal-title">
                        <div class="d-flex justify-content-start">
                            <img class="select-box-icon" width="15" src="@/assets/verified.svg">
                            <div class="modal-title-main">Secured Checkout</div>
                        </div>
                        <p class="modal-title-sub">You are connected to a secure encrypted SSL server.</p>
                    </h5>
                    <h3 class="modal-title">Funds Available: {{ $centsToDollar(currentUser.cash) }}</h3>
                </div>
                <div class="modal-body p-4">
                    <h3 class="modal-heading mb-3 text-center">Select a payment method</h3>
                    <div class="row">
                        <div v-if="settings && parseInt(settings.stripe) !== 0" class="col-4 no-select">
                            <div @click="fetchStripe()" class="select-box text-center" :class="isSelected('stripe')">
                                <img class="select-box-icon" width="60" height="30" src="@/assets/stripe.svg"
                                    draggable="false">
                                <h3>Stripe</h3>
                            </div>
                        </div>
                        <div v-if="settings && parseInt(settings.paypal) !== 0" class="col-4 no-select">
                            <div @click="selected = 'paypal'" class="select-box text-center" :class="isSelected('paypal')">
                                <img class="select-box-icon" width="60" height="30" src="@/assets/paypal.svg"
                                    draggable="false">
                                <h3>Paypal</h3>
                            </div>
                        </div>
                        <div v-if="settings && parseInt(settings.credit_card) !== 0" class="col-4 no-select">
                            <div @click="selected = 'card'" class="select-box text-center" :class="isSelected('card')">
                                <img class="select-box-icon" width="60" height="30" src="@/assets/master-card.svg"
                                    draggable="false">
                                <h3>Credit Card</h3>
                            </div>
                        </div>
                    </div>

                    <div v-if="loading.stripe" class="text-center">
                        <span class="spinner-border spinner-border"></span>
                    </div>
                    <div v-show="selected === 'stripe' && !loading.stripe" class="row mt-5">
                        <div class="col-8 offset-2">
                            <div v-if="error.status" class="form-group mb-4">
                                <div class="alert alert-danger" role="alert">
                                    {{ error.message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-8 offset-2">
                            <div class="form-group mb-4">
                                <label for="cash" class="form-label">Cash</label>
                                <input v-model="stripe.input.cash.value" name="cash" id="cash" type="number"
                                    class="form-control vue-is-valid" min="0" step=".01">
                                <div v-if="stripe.input.cash.error" class="input-error">
                                    {{ stripe.input.cash.error }}
                                </div>
                            </div>
                            <div v-if="!stripe.newPaymentMethod">
                                <DebitCard :last4="stripe.paymentMethod.last_four" :name="stripe.paymentMethod.name"
                                    :brand="stripe.paymentMethod.brand" :expiry-month="stripe.paymentMethod.exp_month"
                                    :expiry-year="stripe.paymentMethod.exp_year" />

                                <div class="text-center mt-3">
                                    <div class="d-flex justify-content-between">
                                        <a @click="stripe.newPaymentMethod = true"
                                            class="text-decoration-none cursor-pointer ms-5">Replace Card</a>
                                        <a @click="removePaymentMethod" class="text-decoration-none cursor-pointer me-5">
                                            <span v-if="loading.remove" class="spinner-border spinner-border-sm"></span>
                                            <span v-else>Remove Card</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div v-show="stripe.newPaymentMethod">
                                <div class="form-group mb-4">
                                    <label for="cash" class="form-label">Card Holder Name</label>
                                    <input v-model="stripe.input.name.value" name="name" id="name" type="text"
                                        class="form-control vue-is-valid">
                                    <div v-if="stripe.input.name.error" class="input-error">
                                        {{ stripe.input.name.error }}
                                    </div>
                                </div>

                                <label for="card" class="form-label">Card</label>
                                <div id="card-element">
                                </div>
                                <div v-if="stripe.paymentMethod !== null" class="mt-4">
                                    <a @click="stripe.newPaymentMethod = false"
                                        class="text-decoration-none cursor-pointer">Use
                                        saved card</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer p-3 border-0">
                    <button @click="$emit('close')" type="button" class="modal-close" data-bs-dismiss="modal">Close</button>
                    <button @click="submit" type="submit" class="btn modal-submit">
                        <span v-if="loading.submit" class="spinner-border spinner-border-sm"></span>
                        <span v-else>Checkout</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DebitCard from '@/components/Stripe/DebitCard'
import Modal from 'bootstrap/js/dist/modal'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js';

export default {
    name: 'CheckoutComponent',
    components: {
        DebitCard
    },
    props: ['cash'],
    created() {
        if (this.settings && parseInt(this.settings.stripe) !== 0) {
            this.fetchStripe()
        }

        if (this.cash > 0) {
            this.stripe.input.cash.value = this.cash / (10 ** 2)
        }
    },
    mounted() {
        this.checkoutModal = new Modal(document.getElementById('checkoutModal'), {
            backdrop: 'static'
        })
        this.checkoutModal.show()
    },
    data() {
        return {
            loading: {
                stripe: false,
                remove: false,
                submit: false,
            },
            stripe: {
                input: {
                    cash: {
                        error: null,
                        value: 0
                    },
                    name: {
                        error: null,
                        value: ''
                    },
                },
                paymentMethod: null,
                intent: null,
                newPaymentMethod: true,

                stripe: '',
                elements: '',
                name: '',
                card: '',
            },

            error: {
                status: false,
                message: null
            },
        }
    },
    methods: {
        async fetchStripe(url = process.env.VUE_APP_API_URL + 'v1/user/stripe') {
            this.selected = 'stripe'
            this.loading.stripe = true

            try {
                const res = await axios.get(url, {
                    headers: this.$authHeader()
                })

                this.stripe.intent = res.data.meta.intent

                if (res.data.meta.paymentMethod) {
                    this.stripe.paymentMethod = res.data.meta.paymentMethod
                    this.stripe.newPaymentMethod = false
                }

                await this.configureStripe()
            } catch (e) {
                throw new Error(e)
            }

            this.loading.stripe = false
        },
        async removePaymentMethod() {
            this.loading.remove = true

            try {
                await axios.delete(process.env.VUE_APP_API_URL + 'v1/user/stripe/pm', {
                    headers: this.$authHeader()
                })

                this.stripe.paymentMethod = null
                this.stripe.newPaymentMethod = true
            } catch (e) {
                throw new Error(e)
            }

            this.loading.remove = false
        },
        async submit() {
            this.stripe.input.cash.error = null
            this.stripe.input.name.error = null
            this.error.status = false
            this.error.message = ''

            if (!this.selected) {
                return this.alertError('Select a payment method.')
            }

            if (this.stripe.input.cash.value === '') {
                this.stripe.input.cash.error = 'Cash is a required field'
                return
            }

            this.stripe.input.cash.value = parseFloat(this.stripe.input.cash.value)

            if (isNaN(this.stripe.input.cash.value) || !Number.isFinite(this.stripe.input.cash.value)) {
                this.stripe.input.cash.error = 'Invalid cash value'
                return
            }
            if (this.stripe.input.cash.value < 1) {
                this.stripe.input.cash.error = 'Cash must be at least $1'
                return
            }
            if (!Number.isInteger(this.stripe.input.cash.value * 100)) {
                this.stripe.input.cash.error = 'Cash must have 2 digits after the decimal point or less'
                return
            }

            this.loading.submit = true

            try {
                if (this.stripe.newPaymentMethod === false && this.stripe.paymentMethod !== null) {
                    await this.purchase(this.stripe.paymentMethod.id)
                } else {
                    if (this.stripe.input.name.value === '') {
                        this.stripe.input.name.error = 'Card holder name is a required field'
                        this.loading.submit = false
                        return
                    }

                    const { setupIntent, error } = await this.stripe.stripe.confirmCardSetup(
                        this.stripe.intent, {
                        payment_method: {
                            card: this.stripe.card,
                            billing_details: {
                                name: this.stripe.input.name.value
                            }
                        }
                    })

                    if (error) {
                        this.alertError(error.message)
                    } else if (setupIntent.status === 'succeeded') {
                        await this.purchase(setupIntent.payment_method)
                    } else {
                        this.alertError("An error occurred!")
                    }
                }
            } catch (e) {
                throw new Error(e)
            }

            this.loading.submit = false
        },

        /*
          Stripe
        */
        async configureStripe() {
            this.stripe.stripe = await loadStripe(this.settings.stripe_publish_key)

            this.stripe.elements = this.stripe.stripe.elements()
            this.stripe.card = this.stripe.elements.create('card', {
                hidePostalCode: true,
                style: {
                    base: {
                        iconColor: '#666EE8',
                        color: '#31325F',
                        fontWeight: 300,
                        fontSize: '15px',

                        '::placeholder': {
                            color: '#CFD7E0'
                        }
                    }
                }
            })

            this.stripe.card.mount('#card-element')
        },
        async purchase(paymentMethodId) {
            try {
                const res = await axios.post(process.env.VUE_APP_API_URL + 'v1/user/stripe', {
                    paymentMethodId: paymentMethodId,
                    cash: this.stripe.input.cash.value * (10 ** 2),
                }, {
                    headers: this.$authHeader()
                })

                this.$store.state.user = res.data.meta.user
                this.$emit('success')
                // this.intent = res.data.meta.intent
                // this.paymentMethod = res.data.meta.paymentMethod
                // this.newPaymentMethod = false

                // this.success.cash = this.input.cash.value * (10 ** 2)
                // this.success.transaction = res.data.meta.transaction
                // this.input.cash.value = 0
                // this.input.name.value = ''
                // this.card.clear()

                // this.success.status = true
                this.checkoutModal.hide()
                this.$emit('close')
            } catch (e) {
                this.alertError(e.message !== undefined ? e.message : e.error)

                throw new Error(e)
            }
        },

        /*
          Helper
        */
        isSelected(selected) {
            return (this.selected === selected) ? 'selected' : null
        },
        alertError(message) {
            this.error.status = true
            this.error.message = message
            this.loading.submit = false
        },
    },
    computed: {
        currentUser() { return this.$store.state.user },
        settings() { return this.$store.state.settings }
    }
}
</script>

<style scoped>
.createdLoading {
    top: 0;
    left: 0;
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-body);
}
</style>
