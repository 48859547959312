<template>
  <div
    v-if="loading.created < loading.createRequests"
    class="main-loading">
    <div class="spinner-border" role="status">
    </div>
  </div>
  <div v-else>
    <div class="notification-view">
      <div class="card border-0 mb-3">
        <HeaderSettings/>

        <div class="card-body">

          <div id="alert">
            <div
              v-if="alert.status"
              class="form-group mb-4">
              <div
                :class="alert.type"
                class="alert"
                role="alert">
                {{ alert.message }}
              </div>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-6">
              <h5 class="pb-3">Order Notifications</h5>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('order', 'new')"
                  :id="getIdByColumns('order', 'new')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                 :for="getIdByColumns('order', 'new')">
                  New
                </label>
              </div>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('order', 'pending')"
                  :id="getIdByColumns('order', 'pending')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                 :for="getIdByColumns('order', 'pending')">
                  Pending
                </label>
              </div>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('order', 'inProgress')"
                  :id="getIdByColumns('order', 'inProgress')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('order', 'inProgress')">
                  In progress
                </label>
              </div>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('order', 'completed')"
                  :id="getIdByColumns('order', 'completed')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('order', 'completed')">
                  Completed
                </label>
              </div>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('order', 'cancelled')"
                  :id="getIdByColumns('order', 'cancelled')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('order', 'cancelled')">
                  Cancelled
                </label>
              </div>
            </div>
            <div class="col-6">
              <h5 class="pb-3">Funds Notifications</h5>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('transaction', 'new')"
                  :id="getIdByColumns('transaction', 'new')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('transaction', 'new')">
                  New
                </label>
              </div>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('transaction', 'pending')"
                  :id="getIdByColumns('transaction', 'pending')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('transaction', 'pending')">
                  Pending
                </label>
              </div>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('transaction', 'completed')"
                  :id="getIdByColumns('transaction', 'completed')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('transaction', 'completed')">
                  Completed
                </label>
              </div>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('transaction', 'failed')"
                  :id="getIdByColumns('transaction', 'failed')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('transaction', 'failed')">
                  Failed
                </label>
              </div>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('transaction', 'refunded')"
                  :id="getIdByColumns('transaction', 'refunded')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('transaction', 'refunded')">
                  Refunded
                </label>
              </div>
            </div>
            <div class="col-6 mt-5">
              <h5 class="pb-3">Ticket Notifications</h5>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('ticket', 'new')"
                  :id="getIdByColumns('ticket', 'new')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('ticket', 'new')">
                  New
                </label>
              </div>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('ticket', 'pending')"
                  :id="getIdByColumns('ticket', 'pending')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('ticket', 'pending')">
                  Pending
                </label>
              </div>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('ticket', 'answered')"
                  :id="getIdByColumns('ticket', 'answered')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('ticket', 'answered')">
                  Answered
                </label>
              </div>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('ticket', 'closed')"
                  :id="getIdByColumns('ticket', 'closed')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('ticket', 'closed')">
                  Closed
                </label>
              </div>
            </div>
            <div class="col-6 mt-5">
              <h5 class="pb-3">Security Notifications</h5>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('auth', 'signedIn')"
                  :id="getIdByColumns('auth', 'signedIn')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('auth', 'signedIn')">
                  Signed In
                </label>
              </div>
            </div>
            <div class="col-6 mt-5">
              <h5 class="pb-3">Account Notifications</h5>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('user', 'verified')"
                  :id="getIdByColumns('user', 'verified')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('user', 'verified')">
                  Verified
                </label>
              </div>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('user', 'unVerified')"
                  :id="getIdByColumns('user', 'unVerified')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('user', 'unVerified')">
                  Un Verified
                </label>
              </div>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('user', 'suspended')"
                  :id="getIdByColumns('user', 'suspended')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('user', 'suspended')">
                  Suspended
                </label>
              </div>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('user', 'banned')"
                  :id="getIdByColumns('user', 'banned')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('user', 'banned')">
                  Banned
                </label>
              </div>
            </div>
            <div class="col-6 mt-5">
              <h5 class="pb-3">Affiliate Notifications</h5>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('affiliate', 'newSignUp')"
                  :id="getIdByColumns('affiliate', 'newSignUp')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('affiliate', 'newSignUp')">
                  New sign-up
                </label>
              </div>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('affiliate', 'commissionPaid')"
                  :id="getIdByColumns('affiliate', 'commissionPaid')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('affiliate', 'commissionPaid')">
                  Commission paid
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <h5 class="pb-3">Newsletter</h5>
              <div class="mb-3 form-check">
                <input
                  v-model="checked"
                  :value="getIdByColumns('newsletter', 'subscribe')"
                  :id="getIdByColumns('newsletter', 'subscribe')"
                  type="checkbox"
                  class="form-check-input">
                <label
                  class="form-check-label"
                  :for="getIdByColumns('newsletter', 'subscribe')">
                  Subscribe to our newsletter?
                </label>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end">
          <button
            @click.prevent="submitForm"
            type="button"
            class="btn btn-custom btn-action">
            <span
              v-show="loading.save"
              class="spinner-border spinner-border-sm">
            </span>
            <span
              v-show="!loading.save">
              Save
            </span>
          </button>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderSettings from '@/components/HeaderSettings'

export default {
  name: 'ProfileView',
  components: {
    HeaderSettings
  },
  created () {
    this.$store.dispatch('notification/created')
  },
  methods: {
    submitForm () {
      this.$store.dispatch('notification/update')
    },

    /* helpers */
    getIdByColumns (module, name) {
      if (this.loading.created < this.loading.createRequests) {
        return
      }

      return this.rows.find((el) => {
        return el.module === module && el.name === name
      }).id
    }
  },
  computed: {
    loading () { return this.$store.state.notification.loading },
    alert () { return this.$store.state.notification.alert },
    rows () { return this.$store.state.notification.rows },
    checked: {
      get () {
        return this.$store.state.notification.checked
      },
      set (value) {
        this.$store.commit('notification/setChecked', value)
      }
    }
  },
  watch: {
    'alert.status': function (val, oldVal) {
      if (val === true) {
        window.scrollTo({
          top: document.getElementById('alert').offsetTop,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>

<style scoped>

h5 {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-default);
}

.form-check-label {
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
  color: var(--text-default);
  user-select: none;
}
</style>
