<template>
    <div class="password-reset-view">
        <div class="body-auth">
            <div class="box-window d-flex justify-content-center align-items-center w-100">
                <div>
                    <div class="d-flex justify-content-center mb-4">
                        <img src="@/assets/logo.svg" alt="Sixerr">
                    </div>
                    <div class="d-flex justify-content-center mb-4">
                        <h1>Verifying Account</h1>
                    </div>

                    <div class="box box-shadow-default">
                        <div v-if="loading" class="w-100 d-flex justify-content-center align-items-center">
                            <span class="spinner-border spinner-border-sm">
                            </span>
                        </div>
                        <div v-if="success">
                            <div class="form-group mb-4">
                                <label for="exampleInputEmail1" class="form-label">Account verified successfully.</label>
                            </div>
                            <router-link :to="{ name: 'login' }" class="btn btn-primary w-100">
                                Login
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VerifyView',
    created() {
        this.$store.dispatch('auth/verify', { token: this.$route.params.token }).then(
            (res) => {
                this.success = true
                this.loading = false
                if (this.currentUser) {
                    this.$store.state.user.status = 'verified'
                    this.$router.push({ name: 'dashboard.index' })
                }
            },
            (error) => {
                this.$router.push({ name: 'dashboard.index' })
                console.log(error)
            }
        )
    },
    data() {
        return {
            loading: true,
            success: false
        }
    },
    computed: {
        isAuthenticated() {
            return this.$store.state.auth.authenticated
        },
        currentUser() {
            return this.$store.state.user
        }
    }
}
</script>

<style scoped></style>
