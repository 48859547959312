<template>
  <div class="credit-card cursor-pointer">
    <div class="credit-card-last4">{{ last4 }}</div>
    <div class="credit-card-name">{{ name }}</div>
    <div class="credit-card-expiry">{{ expiryMonth }}/{{ expiryYear }}</div>
  </div>
</template>

<script>
export default {
  name: 'DebitCard',
  props: ['last4', 'name', 'expiryMonth', 'expiryYear'],
  data () {
    return {
      selected: false
    }
  }
}
</script>

<style scoped>
/* MAIN CREDIT CARD CONTAINER */

.credit-card {
  border-radius: 7px;
  width: 250px;
  height: 120px;
  margin: auto;
  margin-bottom: 24px;
  position: relative;
  transition: all 0.4s ease;
  box-shadow: 0 2px 4px 0 #cfd7df;
  padding: 13px;
  background: var(--purple-default);
  color: var(--white);
}

.active {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.credit-card-last4 {
  font-size: 24px;
}

.credit-card-last4:before {
  content: "**** **** **** ";
  color: var(--white);
  font-size: 20px;
}

.credit-card-name {
  font-size: 18px;
  position: absolute;
  bottom: 8px;
  left: 15px;
}

.credit-card-expiry {
  font-size: 18px;
  position: absolute;
  bottom: 8px;
  right: 15px;
}
</style>
