<template>
  <div class="card border-0 mb-3">
<!--    <div class="card-header bg-transparent border-0">-->
<!--      <div class="d-flex justify-content-between">-->
<!--        <div class="card-header-title">Settings</div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="card-header bg-transparent border-0">
      <div class="d-flex justify-content-between">
        <div class="card-header-button card-header-button-left">
          <span  @click="changeTab('Profile')" :class="this.isActive('Profile') ? 'active' : null">Profile</span>
          <span @click="changeTab('Notification')" :class="this.isActive('Notification') ? 'active' : null">Notification</span>
        </div>
      </div>
    </div>
    <hr class="mt-0 mb-4">
    <div class="card-body">
      <ProfileSettings v-if="isActive('Profile')" />
      <NotifySettings v-if="isActive('Notification')" />

      <router-view/>
    </div>
  </div>
</template>

<script>
import ProfileSettings from '@/components/Settings/ProfileSettings.vue'
import NotifySettings from '@/components/Settings/NotifySettings.vue'

export default {
  name: 'SettingsView',
  components: {
    ProfileSettings,
    NotifySettings
  },
  data () {
    return {
      activeTab: 'Profile'
    }
  },
  methods: {
    changeTab (tab) {
      this.activeTab = tab
    },
    isActive (tab) {
      return this.activeTab === tab
    }
  }
}
</script>

<style scoped>
.card-header {
  padding: 20px 40px 20px 40px;
}
.card-body {
  padding: 40px 40px 40px 40px;
}
</style>
