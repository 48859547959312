<template>

  <a
    href="#"
    class="d-flex text-decoration-none pe-3 anchor anchor-announcement"
    data-bs-toggle="modal"
    :data-bs-target="`#announcement${id}`">
    {{ title }}
  </a>

  <!-- Modal -->
  <div class="modal fade" :id="`announcement${id}`" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <img
          class="modal-close"
          width="24"
          height="24"
          src="@/assets/ic-close.svg"
          data-bs-dismiss="modal"
          aria-label="Close">
        <h5 class="modal-title" id="staticBackdropLabel">{{ title }}</h5>
        <h5 class="modal-date" id="staticBackdropLabel">{{ timestampToDate(created_at) }} - {{ timestampToTime24(created_at) }}</h5>
        <div class="modal-body" v-html="message">
        </div>
        <!--          <a href="#" class="btn-link">Read more</a>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecentAnnouncement',
  props: ['id', 'title', 'message'],
  methods: {
    /* Static global helpers */
    timestampToDate (timestamp) {
      return this.$timestampToDate(timestamp)
    },
    timestampToTime24 (timestamp) {
      return this.$timestampToTime24(timestamp)
    }
  }
}
</script>

<style scoped>
.anchor-announcement {
  font-size: 14px;
  margin-bottom: 16px;
}

.modal-dialog {
  max-width: 640px;
}

.modal-close {
  margin: 16px 16px 22px 16px;
  cursor: pointer;
}

.modal-title {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-title);
  margin: 0 42px 0px 42px;
}

.modal-date {
  font-size: 12px;
  font-weight: normal;
  color: var(--text-title);
  margin: 0 42px 16px 42px;
}

.modal-body {
  font-size: 14px;
  line-height: 28px;
  font-weight: normal;
  color: var(--text-default);
  margin: 0 42px 51px 42px;
  padding: unset;
}
</style>
