import axios from 'axios'
import authHeader from '@/store/auth.header'

export const coupon = {
    namespaced: true,
    actions: {
        show({ state, commit }, code) {
            return axios
                .get(process.env.VUE_APP_API_URL + 'coupon/' + code, {
                    headers: authHeader()
                })
                .then(res => {
                    return Promise.resolve(res.data)
                })
                .catch(err => {
                    return Promise.reject(err.response.data)
                })
        }
    },
}
