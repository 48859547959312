<template>
    <div class="main-loading">
        <div class="spinner-border" role="status">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Spinner',
}
</script>

<style scoped></style>
