<template>
    <AddFunds v-if="funds.status" @success="successAddFundsModal" @close="closeAddFundsModal" :cash="funds.cash" />
    <SuccessModal v-if="alert.status && alert.type === 'alert-success'" />
    <section class="new-order-view">
        <div class="row">
            <div class="col-12">
                <h1 class="view-name">New Order</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-8">
                <div class="row">
                    <div class="col-12">
                        <div class="card border-0 mb-3">
                            <div class="card-header bg-transparent border-0">
                                <div class="d-flex justify-content-between">
                                    <div class="card-header-title"></div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="mb-3">
                                    <h3 class="mb-3">What do you want to order?</h3>
                                    <div class="row">
                                        <div class="col-12 col-lg-4 mb-4 mb-lg-0">
                                            <div class="select2">
                                                <div class="form-select" data-bs-toggle="dropdown" data-bs-display="static"
                                                    aria-expanded="false">
                                                    {{
                                                        input.category.value ? input.category.value.name : 'Pick a category'
                                                    }}
                                                </div>
                                                <ul class="dropdown-menu-profile dropdown-menu p-3">
                                                    <li v-for="item in categories" :key="item.id" class="mb-2">
                                                        <div @click="categorySelected(item)"
                                                            class="dropdown-item text-decoration-none d-flex justify-content-start align-items-center align-content-center cursor-pointer"
                                                            aria-current="page">
                                                            <div v-html="JSON.parse(item.icon)"></div>
                                                            <span class="ps-3">{{ item.name }}</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div v-if="input.category.error" class="input-error">
                                                {{ input.category.error }}
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-8">
                                            <div class="select2">
                                                <div class="form-select" data-bs-toggle="dropdown" data-bs-display="static"
                                                    aria-expanded="false">
                                                    {{
                                                        input.service.value ? input.service.value.name : 'Pick a service'
                                                    }}
                                                </div>
                                                <ul class="dropdown-menu-profile dropdown-menu p-3">
                                                    <li v-for="item in services" :key="item.id" class="mb-2">
                                                        <div @click="serviceSelected(item)"
                                                            class="dropdown-item text-decoration-none d-flex justify-content-start align-items-center align-content-center cursor-pointer"
                                                            aria-current="page">
                                                            <span class="ps-3">{{ item.name }}</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div v-if="input.service.error" class="input-error">
                                                {{ input.service.error }}
                                            </div>
                                        </div>
                                        <div v-if="input.service.value && input.service.value.description"
                                            class="col-12 mt-3">
                                            <div v-html="input.service.value.description"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card border-0 mb-3">
                            <div class="card-header bg-transparent border-0">
                                <div class="d-flex justify-content-between">
                                    <div class="card-header-title"></div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="mb-3">
                                    <label for="url" class="form-label">URL</label>
                                    <input v-model="input.url.value" name="url" id="url" type="text"
                                        class="form-control vue-is-valid"
                                        :placeholder="input.service.value ? input.service.value.demo_url ? input.service.value.demo_url : null : null">
                                    <div v-if="input.url.error" class="input-error">
                                        {{ input.url.error }}
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="quantity" class="form-label">Quantity</label>
                                    <span class="input-label-right">
                                        {{ $centsToDollar(input.cost) }}
                                    </span>
                                    <input v-model="input.quantity.value" name="quantity" id="quantity" type="number"
                                        class="form-control vue-is-valid"
                                        :placeholder="input.service.value ? input.service.value.min : 0"
                                        :min="input.service.value ? input.service.value.min : 0"
                                        :max="input.service.value ? input.service.value.max : 0">
                                    <div class="d-flex justify-content-between">
                                        <div id="inputQuantity" class="form-text">
                                            Min {{ input.service.value ? input.service.value.min : '00,000' }} - Max {{
                                                input.service.value ? input.service.value.max :
                                                '00,000' }}
                                        </div>
                                        <div id="inputQuantity" class="form-text">
                                            Cost per {{ input.service.value ? input.service.value.interval : '0,000' }} is
                                            {{ input.service.value ? costPerInterval(input.service.value.cost,
                                                input.service.value.interval) : '$0,000' }}
                                        </div>
                                    </div>
                                    <div v-if="input.quantity.error" class="input-error">
                                        {{ input.quantity.error }}
                                    </div>
                                </div>
                                <div class="mb-5">
                                    <label for="notes" class="form-label">Notes</label>
                                    <textarea v-model="input.notes.value" name="notes" id="notes"
                                        class="form-control vue-is-valid"
                                        :placeholder="input.service.value ? input.service.value.notes ? input.service.value.notes : null : null"
                                        spellcheck="false"></textarea>
                                    <div v-if="input.notes.error" class="input-error">
                                        {{ input.notes.error }}
                                    </div>
                                </div>
                                <div v-if="input.service.value && input.service.value.addons.length > 0" class="addons">
                                    <div class="mb-3">
                                        <h3 class="m-0">Add more services?</h3>
                                        <label for="labelService2" class="form-text form-label-small m-0">Get discount
                                            on adding more
                                            services!</label>
                                    </div>
                                    <div v-for="addon in input.service.value.addons" :key="addon.id">
                                        <div class="mb-3 d-flex align-items-center">
                                            <input @change="addonCheckHandler($event)" class="form-check-input"
                                                type="checkbox" :value="addon.id" :id="`addon_check_${addon.id}`"
                                                v-model="addonsChecked">
                                            <label class="form-check-label" :for="`addon_check_${addon.id}`">
                                                {{ addon.name }}
                                            </label>
                                        </div>
                                        <div v-if="getIndexOfAddon(addon.id) !== -1">
                                            <div class="mb-3">
                                                <label :for="`addons.service${addon.id}`"
                                                    class="form-label">Quantity</label>
                                                <span class="input-label-right">
                                                    {{ $centsToDollar(input.addons[getIndexOfAddon(addon.id)].cost) }}
                                                </span>
                                                <input v-model="input.addons[getIndexOfAddon(addon.id)].quantity"
                                                    :name="`addons.service${addon.id}`" :id="`addons.service${addon.id}`"
                                                    type="number" class="form-control vue-is-valid" :placeholder="addon.min"
                                                    :min="addon.min" :max="addon.max">
                                                <div class="d-flex justify-content-between">
                                                    <div id="inputQuantity" class="form-text">
                                                        Min {{ addon.min }} -
                                                        Max {{ addon.max }}
                                                    </div>
                                                    <div id="inputQuantity" class="form-text">
                                                        Cost per {{ addon.interval }} is
                                                        {{ costPerInterval(addon.cost, addon.interval) }}
                                                    </div>
                                                </div>
                                                <div v-if="input.addons[getIndexOfAddon(addon.id)].error"
                                                    class="input-error">
                                                    {{ input.addons[getIndexOfAddon(addon.id)].error }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="card border-0 mb-3 position-sticky">
                    <div class="card-header bg-transparent border-0">
                        <div class="d-flex justify-content-between">
                            <div class="card-header-title"></div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="mb-4">
                            <h3 class="title-order">Order Summary</h3>
                        </div>
                        <div class="mb-4 pt-4 border-top">
                            <div v-if="this.input.service.value" class="d-flex justify-content-between">
                                <h5>{{ input.quantity.value }} {{ this.input.service.value.name }}</h5>
                                <h6>{{ $centsToDollar(input.cost) }}</h6>
                            </div>
                            <div v-for="addon in this.input.addons" :key="addon.id" class="d-flex justify-content-between">
                                <h5>{{ addon.quantity }} {{
                                    this.input.service.value.addons[this.getIndexOfAddon(addon.service_id)].name }}</h5>
                                <h6>{{ $centsToDollar(addon.cost) }}</h6>
                            </div>
                        </div>
                        <div class="mb-4 pt-4 border-top">
                            <div v-if="coupon.instance !== null || currentUser.active_subscription !== null"
                                class="d-flex justify-content-between">
                                <h5>Sub Total</h5>
                                <h6>{{ $centsToDollar(this.getSubTotalCost()) }}</h6>
                            </div>
                            <div v-if="currentUser.active_subscription !== null" class="d-flex justify-content-between">
                                <h5>Subscription Discount</h5>
                                <h6>{{ currentUser.active_subscription.plan.discount }}%</h6>
                            </div>

                            <div v-if="coupon.instance !== null" class="d-flex justify-content-between">
                                <h5>Coupon Discount</h5>
                                <h6>{{ coupon.instance.discount }}<span
                                        v-if="coupon.instance.discount_type === 'percentage'">%</span><span v-else>$</span>
                                </h6>
                            </div>

                            <div class="d-flex justify-content-between">
                                <h5 class="fw-bold total">Total Amount</h5>
                                <h6>{{ $centsToDollar(getTotalCost()) }}</h6>
                            </div>

                            <div v-if="!coupon.display" class="d-flex justify-content-between">
                                <h5 @click="coupon.display = true" class="cursor-pointer text-decoration-underline">
                                    Apply Coupon</h5>
                                <h5 v-if="coupon.instance !== null" @click="coupon.instance = null"
                                    class="cursor-pointer text-decoration-underline">Clear Coupon</h5>
                            </div>
                            <div v-if="coupon.display" class="d-flex justify-content-between coupon">
                                <input v-model="coupon.input" class="form-control" type="text" placeholder="Coupon code">
                                <button @click="coupon.display = false" class="btn btn-danger">&cross;</button>
                                <button @click.prevent="applyCoupon" class="btn btn-primary">
                                    <span v-if="coupon.loading" class="spinner-border spinner-border-sm"></span>
                                    <span v-else>Apply</span>
                                </button>
                            </div>
                        </div>
                        <div class="mb-4 pt-4 border-top">
                            <div class="d-flex justify-content-between">
                                <h5 class="funds">Available Funds</h5>
                                <h6 class="funds">{{ $centsToDollar(currentUser.cash) }}</h6>
                            </div>
                        </div>
                        <div class="mb-4 pt-4">
                            <div class="row">
                                <div class="col-12">
                                    <div v-if="alert.status && alert.type === 'alert-danger'" class="form-group mb-4">
                                        <div :class="alert.type" class="alert" role="alert">
                                            {{ alert.message }}
                                        </div>
                                    </div>

                                    <button @click="submit()" class="btn btn-custom btn-order w-100 p-3">
                                        <span v-show="loading.submit" class="spinner-border spinner-border-sm"></span>
                                        <span v-show="!loading.submit">Place Order</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import SuccessModal from '@/components/Modals/SuccessModal'
import AddFunds from '@/components/Modals/AddFunds'
import axios from 'axios'

export default {
    name: 'NewOrderView',
    components: {
        AddFunds,
        SuccessModal,
    },
    data() {
        return {
            loading: {
                render: false,
                submit: false
            },
            coupon: {
                loading: false,
                display: false,
                instance: null,
                input: ''
            },
            alert: {
                status: false,
                type: 0,
                message: null,
                timeout: null
            },
            addonsChecked: [],
            services: [],
            input: {
                cost: null,
                url: {
                    error: null,
                    value: null
                },
                quantity: {
                    error: null,
                    value: null
                },
                notes: {
                    error: null,
                    value: null
                },
                addons: [],
                category: {
                    error: null,
                    value: null
                },
                service: {
                    error: null,
                    value: null
                },
                coupon_id: null
            },
            funds: {
                status: false,
                cash: 0
            }
        }
    },
    methods: {
        async submit(fundSuccess = false) {
            if (this.loading.submit) {
                return
            }

            this.loading.submit = true

            this.alert.status = false
            this.alert.type = null
            this.alert.message = null

            this.input.category.error = null
            this.input.service.error = null
            this.input.url.error = null
            this.input.quantity.error = null
            this.input.notes.error = null
            for (const addon of this.input.addons) {
                addon.error = null
            }

            if (this.input.category.value === null) {
                this.input.category.error = 'Category is a required field'
            }

            if (this.input.service.value === null) {
                this.input.service.error = 'Service is a required field'
            }

            if (this.input.url.value === null) {
                this.input.url.error = 'Url is a required field'
            }

            if (this.input.quantity.value === null) {
                this.input.quantity.error = 'Quantity is a required field'
            } else {
                this.input.quantity.value = parseFloat(this.input.quantity.value);

                if (isNaN(this.input.quantity.value) || !Number.isFinite(this.input.quantity.value)) {
                    this.input.quantity.error = 'Quantity must be a valid number'
                } else {
                    if (this.input.service.value) {
                        if (this.input.quantity.value < this.input.service.value.min) {
                            this.input.quantity.error = `Quantity must be greater than or equal to ${this.input.service.value.min}`
                        } else if (this.input.quantity.value > this.input.service.value.max) {
                            this.input.quantity.error = `Quantity must be less than or equal to ${this.input.service.value.max}`
                        }
                    } else {
                        if (this.input.quantity.value < 1) {
                            this.input.quantity.error = 'Quantity must be greater than or equal to 1'
                        } else if (this.input.quantity.value > 1) {
                            this.input.quantity.error = 'Quantity must be less than or equal to 1'
                        }
                    }
                }
            }

            for (const addon of this.input.addons) {
                if (addon.quantity === null) {
                    addon.error = 'Addon quantity is a required field'
                } else {
                    addon.quantity = parseFloat(addon.quantity);

                    if (isNaN(addon.quantity) || !Number.isFinite(addon.quantity)) {
                        addon.error = 'Addon quantity must be a valid number'
                    } else {
                        if (this.input.service.value) {
                            if (addon.quantity < this.input.service.value.addons[this.getIndexOfAddon(addon.service_id)].min) {
                                addon.error = `Addon quantity must be greater than or equal to ${this.input.service.value.addons[this.getIndexOfAddon(addon.service_id)].min}`
                            } else if (addon.quantity > this.input.service.value.addons[this.getIndexOfAddon(addon.service_id)].max) {
                                addon.error = `Addon quantity must be less than or equal to ${this.input.service.value.addons[this.getIndexOfAddon(addon.service_id)].max}`
                            }
                        } else {
                            if (addon.quantity < 1) {
                                addon.error = 'Addon quantity must be greater than or equal to 1'
                            } else if (addon.quantity > 1) {
                                addon.error = 'Addon quantity must be less than or equal to 1'
                            }
                        }
                    }
                }
            }

            if (
                this.input.category.error ||
                this.input.service.error ||
                this.input.url.error ||
                this.input.quantity.error ||
                this.input.addons.some((addon) => addon.error)
            ) {
                this.loading.submit = false
                return
            }

            const formData = {
                category_id: this.input.category.value.id,
                service_id: this.input.service.value.id,
                cost: this.input.cost,
                url: this.input.url.value,
                quantity: this.input.quantity.value,
                notes: this.input.notes.value,
                addons: this.input.addons,
                coupon_id: this.coupon.instance ? this.coupon.instance.id : null,
            }

            formData.addons = formData.addons.filter(element => {
                return element !== null && element !== undefined && Object.keys(element).length !== 0
            })

            if (this.input.category.value.trail === 'paid' && !fundSuccess && this.currentUser.cash < this.getSubTotalCost()) {
                this.funds.status = true
                this.funds.cash = this.getSubTotalCost() - this.currentUser.cash
                this.loading.submit = false
                return
            }

            try {
                const res = await axios.post(process.env.VUE_APP_API_URL + 'v1/user/order', formData, {
                    headers: this.$authHeader()
                })

                this.$store.state.user = res.data.meta.user

                this.input.category.value = null
                this.input.service.value = null
                this.services = []

                this.input.cost = null
                this.input.url.value = null
                this.input.quantity.value = null
                this.input.notes.value = null
                this.input.addons = []
                this.addonsChecked = []

                this.coupon.input = ''
                this.coupon.instance = null
                this.coupon.display = false
                this.coupon.loading = false

                this.alert.status = true
                this.alert.type = 'alert-success'
                this.alert.message = 'Order placed successfully'
            } catch (e) {
                const err = e.response.data
                this.alert.message = ''

                if (typeof err.errors !== 'undefined') {
                    const errors = err.errors
                    Object.keys(errors).forEach((errorKey) => {
                        errors[errorKey].forEach((errVal, errIdx) => {
                            this.alert.message += (this.alert.message === '') ? errVal : ', ' + errVal
                        })
                    })
                } else if (typeof err.message !== 'undefined') {
                    this.alert.message = err.message
                }

                this.alert.type = 'alert-danger'
                this.alert.status = true

                throw new Error(e)
            }

            this.loading.submit = false
        },
        // addon(serviceID) {
        //     return this.service.addons.find(el => {
        //         return parseInt(el.id) === parseInt(serviceID)
        //     })
        // },
        getIndexOfAddon(serviceID) {
            return this.input.addons.findIndex(el => {
                return parseInt(el.service_id) === parseInt(serviceID)
            })
        },
        costPerInterval(cost, interval) {
            return ((parseInt(cost) / 100) * parseInt(interval)).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
            })
        },
        processCost(quantity, cost) {
            return (parseInt(quantity) * parseInt(cost))
        },
        getSubTotalCost() {
            const totalCostAddons = this.input.addons.reduce((accumulator, el) => {
                return accumulator + parseInt(el.cost)
            }, 0)
            return this.input.cost + totalCostAddons
        },
        getTotalCost() {
            const totalCostAddons = this.input.addons.reduce((accumulator, el) => {
                return accumulator + parseInt(el.cost)
            }, 0)
            const subTotal = parseInt(this.input.cost + totalCostAddons)
            let total = subTotal

            if (this.currentUser.active_subscription !== null) {
                const discount = subTotal * parseInt(this.currentUser.active_subscription.plan.discount) / 100
                total = total - discount
            }
            if (this.coupon.instance !== null) {
                if (this.coupon.instance.discount_type === 'percentage') {
                    const discount = subTotal * parseInt(this.coupon.instance.discount) / 100
                    total = total - discount
                } else {
                    const discount = parseInt(this.coupon.instance.discount) * (10 ** 2)
                    total = total - discount
                }
            }

            return total
        },
        addonCheckHandler(e) {
            const serviceID = e.target.value
            // const schemaAddons = this.schema.fields.addons.fields
            const found = this.input.addons.some(el => el.service_id === serviceID)

            if (found && e.target.checked === false) {
                this.input.addons = this.input.addons.filter(function (el) {
                    return el.service_id !== serviceID
                })
                // delete schemaAddons['service' + serviceID]
            } else if (!found) {
                this.input.addons.push({
                    service_id: serviceID,
                    quantity: null,
                    cost: null,
                    error: null
                })
                // schemaAddons['service' + serviceID] = yup.number().typeError('Quantity must be a number').required('Quantity is a required field').min(this.addon(serviceID).min, `Quantity must be greater than or equal to ${this.addon(serviceID).min}`).max(this.addon(serviceID).max, `Quantity must be less than or equal to ${this.addon(serviceID).max}`).nullable()
            }

            // this.schema.fields.addons = yup.object(schemaAddons)
        },
        successAddFundsModal() {
            console.log('ttt')
            this.submit(true)
        },
        closeAddFundsModal() {
            this.funds.status = false
        },
        debug(arg) {
            console.log(arg)
        },

        /* coupons */
        applyCoupon() {
            this.coupon.loading = true
            this.alert.status = false
            this.alert.type = null
            this.alert.message = null

            if (this.coupon.input === '') {
                this.alert.status = true
                this.alert.type = 'alert-danger'
                this.alert.message = 'Coupon code is required.'
                this.coupon.loading = false
            } else {
                this.$store.dispatch('coupon/show', this.coupon.input).then((res) => {
                    this.coupon.input = ''
                    this.coupon.instance = res
                    this.coupon.loading = false
                    this.coupon.display = false
                }).catch(error => {
                    this.alert.message = ''

                    if (typeof error.errors !== 'undefined') {
                        const errors = error.errors
                        Object.keys(errors).forEach((errorKey) => {
                            errors[errorKey].forEach((errVal, errIdx) => {
                                this.alert.message += (this.alert.message === '') ? errVal : ', ' + errVal
                            })
                        })
                    } else if (typeof error.message !== 'undefined') {
                        this.alert.message = error.message
                    }

                    this.alert.type = 'alert-danger'
                    this.alert.status = true
                    this.coupon.loading = false
                })
            }
        },

        categorySelected(category) {
            this.input.category.value = category
            this.services = category.services
            this.input.service.value = null
            this.resetInputs()
        },
        serviceSelected(service) {
            this.input.service.value = service
            this.resetInputs()
        },
        resetInputs() {
            this.input.cost = null
            this.input.url.value = null
            this.input.quantity.value = null
            this.input.notes.value = null
            this.input.addons = []
            this.addonsChecked = []
        }
    },
    mounted() {
    },
    computed: {
        currentUser() {
            return this.$store.state.user
        },
        categories() {
            return this.$store.state.categories
        }
    },
    watch: {
        'input.quantity.value': function (val, oldVal) {
            if (val !== '' && this.input.service.value) {
                this.input.cost = this.processCost(val, this.input.service.value.cost)
            }
        },
        'input.addons': {
            deep: true,
            handler: function (after) {
                for (let index = 0; index < after.length; index++) {
                    const addon = after[index]
                    if (addon.quantity !== null && addon.quantity !== '' && addon.quantity !== 0) {
                        this.input.addons[index].cost = this.processCost(addon.quantity, this.input.service.value.addons[this.getIndexOfAddon(addon.service_id)].cost)
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
h3 {
    font-size: 16px;
    font-weight: bold;
    color: var(--text-title);
}

.title-order {
    font-size: 18px;
}

h5 {
    font-size: 14px;
    font-weight: normal;
    color: var(--text-default);
}

h6 {
    font-size: 14px;
    font-weight: bold;
    color: var(--text-title);
}

input {
    font-size: 14px;
    font-weight: normal;
    border-radius: 7px;
    background-color: #f7f7f7;
    border-color: var(--border-default);
    color: var(--text-default);
}

.form-check-label {
    font-size: 14px;
    font-weight: normal;
    color: var(--text-default);
}

input[type="checkbox"] {
    margin: unset;
}

input:focus {
    font-size: 14px;
    font-weight: normal;
    border-radius: 6px;
    background-color: #f7f7f7;
    border-color: var(--border-default);
    color: var(--text-default);

    outline: unset;
    box-shadow: unset;
}

input[type="checkbox"] {
    margin-right: 10px;
}

.form-text {
    font-size: 11px;
    font-weight: normal;
    color: var(--text-default);
}

.form-label-small {
    font-size: 11px;
}

.position-sticky {
    top: 20px;
}

.total {
    color: var(--text-title);
}

.funds {
    font-size: 14px;
    font-weight: bold;
    color: var(--green-default);
}

.btn-order {
    font-size: 22px;
    font-weight: bold;
    border-radius: 8px;
}

.coupon .btn-danger {
    font-size: 14px;
    border-radius: unset;
    background-color: #FFD6CB;
    border-color: #FFD6CB;
    color: #FF7D7B;
}

.coupon .btn-primary {
    font-size: 14px;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    background-color: #E7F9F1;
    border-color: #E7F9F1;
    color: #3ACE7C;
}

.coupon input {
    font-size: 14px;
    font-weight: normal;
    border-radius: 7px 0 0 7px;
    background-color: #f7f7f7;
    border-color: var(--border-default);
    color: var(--text-default);
}
</style>
