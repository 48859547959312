<template>
  <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 border-top">
    <div class="col-md-6 d-flex align-items-center justify-content-start">
      <span class="text-muted ms-5">© 2022, Sixerr LLC.</span>
    </div>
    <div class="col-md-6 d-flex align-items-center justify-content-end">
      <a href="#" class="text-muted me-3 text-decoration-none external-link">Privacy Policy</a>
      <a href="#" class="text-muted me-3 text-decoration-none external-link">Terms of Service</a>
      <a href="#" class="text-muted me-5 text-decoration-none external-link">Refund Policy</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style scoped>

@media (min-width: 768px) {
  footer {
    margin-left: 230px;
  }
}

.text-muted {
  color: var(--text-default)!important;
  font-size: 12px;
}
.external-link:hover {
  color: var(--text-title)!important;
}

</style>
