<template>
    <Spinner v-if="loading.render" />
    <div v-else>
        <TitleModule :title="$options.title" />
        <StatsModule v-if="stats" :data="stats" @moduleClick="(val) => { dispatchChangeView(val) }" />

        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-8">
                <div class="card border-0 mb-3">
                    <div class="card-header bg-transparent border-0">
                        <div class="d-flex justify-content-between">
                            <div class="card-header-title card-header-custom">Recent Activity</div>
                            <div class="card-header-button">
                                <span @click="dispatchChangeTab('orders')"
                                    :class="tab === 'orders' && 'active'">Orders</span>
                                <span @click="dispatchChangeTab('funds')" :class="tab === 'funds' && 'active'">Funds</span>
                                <span @click="dispatchChangeTab('tickets')"
                                    :class="tab === 'tickets' && 'active'">Tickets</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <RecentOrders v-if="tab === 'orders'" :rows="orders" />
                        <RecentFunds v-if="tab === 'funds'" :rows="funds" />
                        <RecentTickets v-if="tab === 'tickets'" :rows="tickets" />
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="card border-0 mb-3">
                    <div class="card-header card-header-custom bg-transparent border-0">Announcements</div>
                    <div class="card-body pt-4 pb-4">
                        <div v-if="announcements.length > 0">
                            <RecentAnnouncement v-for="row in announcements" :key="row.id" :id="row.id" :title="row.title"
                                :message="row.message" />
                            <router-link :to="{ name: 'announcement.index' }"
                                class="d-flex justify-content-end text-decoration-none pe-3 anchor" aria-current="page">
                                View All
                            </router-link>
                        </div>
                        <div v-else class="d-flex justify-content-center align-items-center" style="min-height: 431px;">
                            <h5>No data found</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RecentOrders from '@/components/Dashboard/RecentOrders.vue'
import RecentFunds from '@/components/Dashboard/RecentFunds.vue'
import RecentTickets from '@/components/Dashboard/RecentTickets.vue'
import RecentAnnouncement from '@/components/Dashboard/RecentAnnouncement.vue'
import TitleModule from '@/components/Modules/TitleModule'
import StatsModule from '@/components/Modules/StatsModule'
import Spinner from '@/components/Plugin/Spinner.vue'
import axios from 'axios'

export default {
    title: 'Dashboard',
    name: 'DashboardView',
    components: {
        StatsModule,
        TitleModule,
        RecentOrders,
        RecentFunds,
        RecentTickets,
        RecentAnnouncement,
        Spinner
    },
    created() {
        this.index()
    },
    data() {
        return {
            loading: {
                render: true
            },
            stats: null,
            orders: [],
            funds: [],
            tickets: [],
            announcements: [],
            tab: 'orders',
        }
    },
    methods: {
        async index(url = process.env.VUE_APP_API_URL + 'v1/user/dashboard') {
            try {
                const res = await axios.get(url, {
                    headers: this.$authHeader()
                })

                this.stats = [
                    {
                        id: 1,
                        title: 'Orders',
                        slug: 'orders',
                        value: res.data.meta.stats.orders,
                        info: 'in progress',
                        svg: 'dashboardOrders'
                    },
                    {
                        id: 2,
                        title: 'Funds',
                        slug: 'funds',
                        value: this.$centsToDollar(res.data.meta.stats.funds),
                        info: 'available credit',
                        svg: 'dashboardFunds'
                    },
                    {
                        id: 3,
                        title: 'Tickets',
                        slug: 'tickets',
                        value: res.data.meta.stats.tickets,
                        info: 'in process',
                        svg: 'dashboardTickets'
                    },
                    {
                        id: 4,
                        title: 'Membership',
                        slug: 'membership',
                        value: res.data.meta.stats.membership,
                        info: 'active discount',
                        svg: 'dashboardMembership'
                    }
                ]

                this.orders = res.data.meta.recent.orders
                this.funds = res.data.meta.recent.funds
                this.tickets = res.data.meta.recent.tickets
                this.announcements = res.data.meta.announcements
                this.loading.render = false
            } catch (e) {
                throw new Error(e)
            }
        },
        dispatchChangeTab(tab = null) {
            this.tab = tab
        },
        dispatchChangeView(tab = null) {
            switch (tab) {
                case 1:
                    this.$router.push({ name: 'order.index' })
                    break
                case 2:
                    this.$router.push({ name: 'fund.index' })
                    break
                case 3:
                    this.$router.push({ name: 'ticket.index' })
                    break
                case 4:
                    this.$router.push({ name: 'subscription.index' })
                    break
            }
        }
    }
}
</script>

<style scoped></style>
