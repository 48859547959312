<template>
    <div class="header-view">
        <nav class="navbar off-navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
                <a class="navbar-brand" href="#">
                </a>

                <div class="d-flex align-items-center">

                    <div class="btn-group me-4">
                        <div class="order-btn-circle d-flex justify-content-center align-items-center"
                            data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                        </div>
                        <ul class="dropdown-menu-profile dropdown-menu dropdown-menu-end p-3" style="width:200px">
                            <li class="mb-2">
                                <router-link :to="{ name: 'order.store' }"
                                    class="dropdown-item text-decoration-none d-flex justify-content-start align-items-center align-content-center"
                                    aria-current="page">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                        fill="none" stroke="#CCDDFF" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <path
                                            d="M6 2L3 6v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V6l-3-4H6zM3.8 6h16.4M16 10a4 4 0 1 1-8 0" />
                                    </svg>
                                    <span class="ps-3">New Order</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link :to="{ name: 'fund.index' }"
                                    class="dropdown-item text-decoration-none d-flex justify-content-start align-items-center align-content-center"
                                    aria-current="page">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-receipt-2"
                                        width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#CCDDFF"
                                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path
                                            d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2" />
                                        <path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5" />
                                    </svg>
                                    <span class="ps-3">Add Funds</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link :to="{ name: 'ticket.store' }"
                                    class="dropdown-item text-decoration-none d-flex justify-content-start align-items-center align-content-center"
                                    aria-current="page">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-device-mobile-message" width="20" height="20"
                                        viewBox="0 0 24 24" stroke-width="1.5" stroke="#CCDDFF" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M11 3h10v8h-3l-4 2v-2h-3z" />
                                        <path d="M15 16v4a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1h2" />
                                        <path d="M10 18v.01" />
                                    </svg>
                                    <span class="ps-3">Open Ticket</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>


                    <router-link to="/funds" class="text-decoration-none">
                        <button type="button" class="d-none d-md-block btn btn-outline-success me-4">
                            {{ centsToDollar(currentUser.cash) }}
                        </button>
                    </router-link>

                    <div class="btn-group">
                        <img v-if="currentUser.avatar" :src="currentUser.avatar.original_url"
                            class="rounded-circle dropdown-toggle cursor-pointer" width="36" height="36"
                            alt="user profile picture" loading="lazy" data-bs-toggle="dropdown" data-bs-display="static"
                            aria-expanded="false" />
                        <img v-else src="@/assets/user-2.svg" class="rounded-circle dropdown-toggle cursor-pointer"
                            width="36" height="36" alt="user profile picture" loading="lazy" data-bs-toggle="dropdown"
                            data-bs-display="static" aria-expanded="false" />
                        <ul class="dropdown-menu-profile dropdown-menu dropdown-menu-end">
                            <div class="dropdown-header-box p-3 pt-1">
                                <li>
                                    <h6 class="dropdown-header"></h6>
                                    <div class="d-flex align-content-start">

                                        <img v-if="currentUser.avatar" :src="currentUser.avatar.original_url"
                                            class="rounded-circle dropdown-toggle cursor-pointer" width="50" height="50"
                                            alt="user profile picture" loading="lazy" data-bs-toggle="dropdown"
                                            data-bs-display="static" aria-expanded="false" />
                                        <img v-else src="@/assets/user-2.svg" class="rounded-circle dropdown-toggle"
                                            width="50" height="50" alt="Black and White Portrait of a Man" loading="lazy"
                                            data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" />
                                        <div class="ms-3">
                                            <h2>{{ this.currentUser.name }}</h2>
                                            <h5>{{ this.currentUser.email }}</h5>
                                            <span class="cursor-pointer">Monthly Basic</span>
                                        </div>
                                    </div>
                                </li>
                            </div>

                            <li>
                                <router-link :to="{ name: 'user.show' }" class="dropdown-item text-decoration-none">
                                    Profile
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/notification" class="dropdown-item text-decoration-none"
                                    v-slot="{ route }">
                                    {{ route.name }}
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'user.billing' }" class="dropdown-item text-decoration-none">
                                    Billing
                                </router-link>
                            </li>
                            <li><a class="dropdown-item action" @click.prevent="logOut">
                                    Logout
                                </a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'TopBar',
    created() {
    },
    methods: {
        logOut() {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push({ name: 'login' })
                this.$router.go()
            })
        },

        /* Static global helpers */
        centsToDollar(cents) {
            return this.$centsToDollar(cents)
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.user
        }
    }
}
</script>

<style scoped>
.navbar-text {
    color: var(--text-orange);
    font-size: 11px;
    font-weight: 600;
}

.navbar {
    /*background-color: var(--bg-major) !important;*/
    /*box-shadow: 0 1px 4px rgb(21 34 50 / 15%);*/
}

button {
    font-size: 16px;
    font-weight: 600;
    color: var(--green-default);
    border: 1px solid var(--green-default);
    padding: 0.075rem 0.75rem;
}

/*notify*/
.dropdown-menu {
    margin-top: 1.35rem;
    padding: unset;
}

.order-btn-circle {
    padding: 7px;
    border-radius: 50%;
    background-color: var(--purple-default);
    cursor: pointer;
}
</style>
