<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="7.771" height="8.998" viewBox="0 0 7.771 8.998"><path id="ic_content_copy_24px" d="M7.726,1H2.818A.82.82,0,0,0,2,1.818V7.544h.818V1.818H7.726ZM8.953,2.636h-4.5a.82.82,0,0,0-.818.818V9.18A.82.82,0,0,0,4.454,10h4.5a.82.82,0,0,0,.818-.818V3.454A.82.82,0,0,0,8.953,2.636Zm0,6.544h-4.5V3.454h4.5Z" transform="translate(-2 -1)" fill="#5a607f"/></svg>
</template>

<script>
export default {
  name: 'IconCopy'
}
</script>

<style scoped>

</style>
