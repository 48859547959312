<template>
  <div class="table-responsive">
    <table class="table align-middle">
        <thead>
        <tr>
          <th
            v-for="column in columns"
            :key="column.name"
            :style="[(column.width) ? 'width:'+column.width+';' : null]"
            scope="col">
            {{column.label}}
          </th>
        </tr>
        </thead>
        <slot></slot>
      </table>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Datatable',
  props: ['columns']
}
</script>

<style scoped>

</style>
