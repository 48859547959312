<template>
  <footer class="position-fixed left-0 bottom-0 w-100 py-3">
    <div class="row">
      <div class="col-6">
        <span class="text-muted ps-2">© 2022, Sixerr LLC.</span>
      </div>
      <div class="col-6 text-end">
        <a href="#" class="text-muted text-decoration-none external-link pe-2">Terms of Service</a>
      </div>
    </div>
  </footer>
<!--  <footer class="position-fixed left-0 bottom-0 w-100 d-flex flex-wrap justify-content-between align-items-center py-3">-->
<!--    <div class="col-md-6 d-flex align-items-center justify-content-start">-->
<!--      <span class="text-muted ms-5">© 2022, Sixerr LLC.</span>-->
<!--    </div>-->
<!--    <div class="col-md-6 d-flex align-items-center justify-content-end">-->
<!--      <a href="#" class="text-muted me-3 text-decoration-none external-link">Terms of Service</a>-->
<!--    </div>-->
<!--  </footer>-->
</template>

<script>
export default {
  name: 'FooterAuthComponent'
}
</script>

<style scoped>
footer {
  background-color: var(--bg-body-auth);
}
.text-muted {
  color: var(--text-default)!important;
  font-size: 12px;
}
.external-link:hover {
  color: var(--text-title)!important;
}

</style>
