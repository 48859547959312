<template>
  <div class="funds-history-view">
    <div class="row">
      <div class="col-12">
        <h1 class="view-name">Funds History</h1>
      </div>
    </div>
    <div class="card border-0 mb-3">
      <div class="card-header bg-transparent ">
        <div class="d-flex justify-content-between">
          <div class="d-flex w-100">
            <div class="card-header-button card-header-button-left">
              <span @click="changeTab('All')" :class="this.isActive('All') ? 'active' : null">All</span>
              <span @click="changeTab('Pending')" :class="this.isActive('Pending') ? 'active' : null">Pending</span>
              <span @click="changeTab('Completed')" :class="this.isActive('Completed') ? 'active' : null">Completed</span>
              <span @click="changeTab('Failed')" :class="this.isActive('Failed') ? 'active' : null">Failed</span>
              <span @click="changeTab('Refunded')" :class="this.isActive('Refunded') ? 'active' : null">Refunded</span>
            </div>
            <div class="input-group">
              <img @click="searchBoxToggle()" class="input-group-text cursor-pointer" src="@/assets/ic-search.svg">
              <input v-if="searchBox" ref="searchBox" type="text" class="form-control" id="specificSizeInputGroupUsername" placeholder="Search Here...">
            </div>
          </div>
          <div class="card-header-button">
            <button type="button" class="btn btn-custom btn-action">Add Funds</button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table align-middle">
            <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">TID</th>
              <th scope="col">Method</th>
              <th scope="col">Amount</th>
              <th scope="col">Invoice</th>
              <th scope="col" style="width: 80px">Status</th>
              <th scope="col" style="width: 80px" class="text-center">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>May 12, 2022<br><span>12:05 PM</span></td>
              <td>ABU123XYX2314</td>
              <td>Paypal<br><span>PK12345678912</span></td>
              <td>20,000 $</td>
              <td>
                <img
                  width="12"
                  height="12"
                  src="@/assets/ic-download.svg">
              </td>
              <td>
                <button type="button" class="btn btn-status btn-pending">Pending</button>
              </td>
              <td class="text-center">
                <div class="btn-group dropstart">
                  <svg
                    class="me-2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
                  </svg>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#">Open Ticket</a></li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <td>May 12, 2022<br><span>12:05 PM</span></td>
              <td>ABU123XYX2314</td>
              <td>Paypal<br><span>PK12345678912</span></td>
              <td>20,000 $</td>
              <td>
                <img
                  width="12"
                  height="12"
                  src="@/assets/ic-download.svg">
              </td>
              <td>
                <button type="button" class="btn btn-status btn-pending">Pending</button>
              </td>
              <td class="text-center">
                <div class="btn-group dropstart">
                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                  </div>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#">Open Ticket</a></li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <td>May 12, 2022<br><span>12:05 PM</span></td>
              <td>ABU123XYX2314</td>
              <td>Paypal<br><span>PK12345678912</span></td>
              <td>20,000 $</td>
              <td>
                <img
                  width="12"
                  height="12"
                  src="@/assets/ic-download.svg">
              </td>
              <td>
                <button type="button" class="btn btn-status btn-pending">Pending</button>
              </td>
              <td class="text-center">
                <div class="btn-group dropstart">
                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                  </div>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#">Open Ticket</a></li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <td>May 12, 2022<br><span>12:05 PM</span></td>
              <td>ABU123XYX2314</td>
              <td>Paypal<br><span>PK12345678912</span></td>
              <td>20,000 $</td>
              <td>
                <img
                  width="12"
                  height="12"
                  src="@/assets/ic-download.svg">
              </td>
              <td>
                <button type="button" class="btn btn-status btn-pending">Pending</button>
              </td>
              <td class="text-center">
                <div class="btn-group dropstart">
                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                  </div>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#">Open Ticket</a></li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <td>May 12, 2022<br><span>12:05 PM</span></td>
              <td>ABU123XYX2314</td>
              <td>Paypal<br><span>PK12345678912</span></td>
              <td>20,000 $</td>
              <td>
                <img
                  width="12"
                  height="12"
                  src="@/assets/ic-download.svg">
              </td>
              <td>
                <button type="button" class="btn btn-status btn-pending">Pending</button>
              </td>
              <td class="text-center">
                <div class="btn-group dropstart">
                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                  </div>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#">Open Ticket</a></li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <td>May 12, 2022<br><span>12:05 PM</span></td>
              <td>ABU123XYX2314</td>
              <td>Paypal<br><span>PK12345678912</span></td>
              <td>20,000 $</td>
              <td>
                <img
                  width="12"
                  height="12"
                  src="@/assets/ic-download.svg">
              </td>
              <td>
                <button type="button" class="btn btn-status btn-pending">Pending</button>
              </td>
              <td class="text-center">
                <div class="btn-group dropstart">
                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                  </div>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#">Open Ticket</a></li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <td>May 12, 2022<br><span>12:05 PM</span></td>
              <td>ABU123XYX2314</td>
              <td>Paypal<br><span>PK12345678912</span></td>
              <td>20,000 $</td>
              <td>
                <img
                  width="12"
                  height="12"
                  src="@/assets/ic-download.svg">
              </td>
              <td>
                <button type="button" class="btn btn-status btn-pending">Pending</button>
              </td>
              <td class="text-center">
                <div class="btn-group dropstart">
                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                  </div>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#">Open Ticket</a></li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <td>May 12, 2022<br><span>12:05 PM</span></td>
              <td>ABU123XYX2314</td>
              <td>Paypal<br><span>PK12345678912</span></td>
              <td>20,000 $</td>
              <td>
                <img
                  width="12"
                  height="12"
                  src="@/assets/ic-download.svg">
              </td>
              <td>
                <button type="button" class="btn btn-status btn-pending">Pending</button>
              </td>
              <td class="text-center">
                <div class="btn-group dropstart">
                  <div class="action" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                  </div>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#">Open Ticket</a></li>
                  </ul>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FundsHistoryView',
  components: {
  },
  data () {
    return {
      activeTab: 'All',
      searchBox: false
    }
  },
  methods: {
    changeTab (tab) {
      this.activeTab = tab
    },
    isActive (tab) {
      return this.activeTab === tab
    },
    searchBoxToggle () {
      this.searchBox = !this.searchBox

      if (this.searchBox) {
        console.log(this.$refs.searchBox)
        this.$refs.searchBox.focus()
      }
    }
  }
}
</script>

<style scoped>

</style>
