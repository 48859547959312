import axios from 'axios'
import authHeader from '@/store/auth.header'

export const setting = {
    namespaced: true,
    actions: {
        dashboard_index() {
            return axios
                .get(process.env.VUE_APP_API_URL + 'setting/dashboard/index', {
                    headers: authHeader()
                })
                .then(res => {
                    return Promise.resolve(res.data)
                })
                .catch(err => {
                    return Promise.reject(err.response.data)
                })
        },
        order_stats() {
            return axios
                .get(process.env.VUE_APP_API_URL + 'setting/order/stats', {
                    headers: authHeader()
                })
                .then(res => {
                    return Promise.resolve(res.data)
                })
                .catch(err => {
                    return Promise.reject(err.response.data)
                })
        },
        fund_stats() {
            return axios
                .get(process.env.VUE_APP_API_URL + 'setting/fund/stats', {
                    headers: authHeader()
                })
                .then(res => {
                    return Promise.resolve(res.data)
                })
                .catch(err => {
                    return Promise.reject(err.response.data)
                })
        },
        affiliate_stats() {
            return axios
                .get(process.env.VUE_APP_API_URL + 'setting/affiliate/stats', {
                    headers: authHeader()
                })
                .then(res => {
                    return Promise.resolve(res.data)
                })
                .catch(err => {
                    return Promise.reject(err.response.data)
                })
        }
    }
}
