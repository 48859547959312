<template>
    <div class="profile-view">
        <div class="card border-0 mb-3">
            <HeaderSettings />

            <div class="card-body">
                <div v-if="loading.render" class="d-flex justify-content-center">
                    <span class="spinner-border spinner-border"></span>
                </div>
                <div v-else>
                    <div class="row">
                        <div class="col-12">
                            <div v-if="error.status" class="form-group mb-4">
                                <div class="alert alert-danger" role="alert">
                                    {{ error.message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12">

                            <div v-if="paymentMethod && !newPaymentMethod">
                                <table class="table h-100 m-0">
                                    <tr>
                                        <th>Name</th>
                                        <td>{{ paymentMethod.name }}</td>
                                    </tr>
                                    <tr>
                                        <th>Number</th>
                                        <td>**** **** **** {{ paymentMethod.last_four }}</td>
                                    </tr>
                                    <tr>
                                        <th>Brand</th>
                                        <td>{{ paymentMethod.brand }}</td>
                                    </tr>
                                    <tr>
                                        <th>Expire</th>
                                        <td>{{ paymentMethod.exp_month }}/{{ paymentMethod.exp_year }}</td>
                                    </tr>
                                </table>
                                <div v-if="paymentMethod" class="d-flex justify-content-end">
                                    <button @click="removePaymentMethod" type="button"
                                        class="btn btn-custom btn-cancel me-3">
                                        <span v-if="loading.remove" class="spinner-border spinner-border-sm"></span>
                                        <span v-else>Remove Card</span>
                                    </button>
                                    <button @click="newPaymentMethod = true" type="button"
                                        class="btn btn-custom btn-action">
                                        Replace Card
                                    </button>
                                </div>
                            </div>
                            <div v-show="!paymentMethod || newPaymentMethod">
                                <div class="form-group mb-4">
                                    <label for="cash" class="form-label">Card Holder Name</label>
                                    <input v-model="input.name.value" name="name" id="name" type="text"
                                        class="form-control vue-is-valid">
                                    <div v-if="input.name.error" class="input-error">
                                        {{ input.name.error }}
                                    </div>
                                </div>

                                <label for="card" class="form-label">Card</label>
                                <div id="card-element">
                                </div>
                                <div class="d-flex justify-content-end mt-3">
                                    <button v-if="paymentMethod" @click="newPaymentMethod = false" type="button"
                                        class="btn btn-custom btn-cancel me-3">
                                        Back
                                    </button>
                                    <button @click="submit" class="btn btn-custom btn-action">
                                        <span v-show="loading.submit" class="spinner-border spinner-border-sm">
                                        </span>
                                        <span v-show="!loading.submit">
                                            Save
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderSettings from '@/components/HeaderSettings'
import SimpleInput from '@/components/Form/SimpleInput'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js';

export default {
    name: 'BillingView',
    components: {
        SimpleInput,
        HeaderSettings
    },
    created() {
        this.index()
    },
    mounted() {
    },
    data() {
        return {
            loading: {
                render: true,
                submit: false,
                remove: false
            },
            error: {
                status: false,
                message: null
            },
            input: {
                name: {
                    error: null,
                    value: ''
                }
            },
            paymentMethod: null,
            intent: null,
            newPaymentMethod: false,

            /*
              Stripe
            */
            stripe: '',
            elements: '',
            card: '',
        }
    },
    methods: {
        async index(url = process.env.VUE_APP_API_URL + 'v1/user/stripe') {
            try {
                const res = await axios.get(url, {
                    headers: this.$authHeader()
                })

                this.intent = res.data.meta.intent

                if (res.data.meta.paymentMethod) {
                    this.paymentMethod = res.data.meta.paymentMethod
                    this.newPaymentMethod = false
                }

                this.loading.render = false

                this.$nextTick(() => {
                    this.configureStripe()
                });
            } catch (e) {
                this.loading.render = false

                throw new Error(e)
            }
        },
        async submit() {
            this.input.name.error = null
            this.error.status = false
            this.error.message = null

            if (this.input.name.value === '') {
                this.input.name.error = 'Card holder name is a required field'
                return
            }

            this.loading.submit = true

            try {
                const { setupIntent, error } = await this.stripe.confirmCardSetup(
                    this.intent, {
                    payment_method: {
                        card: this.card,
                        billing_details: {
                            name: this.input.name.value
                        }
                    }
                })

                if (error) {
                    this.alertError(error.message)
                } else if (setupIntent.status === 'succeeded') {
                    await this.addPaymentMethod(setupIntent.payment_method)
                } else {
                    this.alertError("An error occurred!")
                }
            } catch (e) {
                throw new Error(e)
            }

            this.loading.submit = false
        },
        async removePaymentMethod() {
            this.loading.remove = true

            try {
                await axios.delete(process.env.VUE_APP_API_URL + 'v1/user/stripe/pm', {
                    headers: this.$authHeader()
                })

                this.paymentMethod = null
                this.newPaymentMethod = true

                this.input.name.value = ''

                this.card.clear()
            } catch (e) {
                throw new Error(e)
            }

            this.loading.remove = false
        },

        /*
          Stripe
        */
        async configureStripe() {
            this.stripe = await loadStripe(this.settings.stripe_publish_key)

            this.elements = this.stripe.elements()
            this.card = this.elements.create('card', {
                hidePostalCode: true,
                style: {
                    base: {
                        iconColor: '#666EE8',
                        color: '#31325F',
                        fontWeight: 300,
                        fontSize: '15px',

                        '::placeholder': {
                            color: '#CFD7E0'
                        }
                    }
                }
            })

            this.card.mount('#card-element')
        },

        /*
          methods
        */
        async addPaymentMethod(paymentMethodId) {
            try {
                const res = await axios.post(process.env.VUE_APP_API_URL + 'v1/user/stripe/pm', {
                    paymentMethodId: paymentMethodId,
                }, {
                    headers: this.$authHeader()
                })

                this.$store.state.user = res.data.meta.user
                this.intent = res.data.meta.intent
                this.paymentMethod = res.data.meta.paymentMethod
                this.newPaymentMethod = false

                this.card.clear()
            } catch (e) {
                this.alertError(this.$errorFromException(e))

                throw new Error(e)
            }
        },

        /*
          helpers
        */
        alertError(message) {
            this.error.status = true
            this.error.message = message
            this.loading.add = false
        }
    },
    computed: {
        currentUser() { return this.$store.state.user },
        settings() { return this.$store.state.settings }
    },
    watch: {
        // add: function (newVal, oldVal) {
        //     if (newVal === true) {
        //         this.configureStripe()
        //     } else {
        //         this.stripe = ''
        //         this.elements = ''
        //         this.card = ''
        //         this.intentToken = ''
        //     }
        // }
    }
}
</script>

<style scoped>
tr th {
    width: 150px;
}

tr:hover th,
tr:hover td {
    background-color: unset;
    border-color: var(--hover-border-default);
}

.edit-picture {
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    width: 220px;
    height: 220px;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

/* input {
    border: unset;
    padding: unset;
    height: 100%;
    width: 100%;
} */
</style>
