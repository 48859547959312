<template>
    <section class="single-ticket-view">
        <Spinner v-if="loading.render" />
        <div v-else class="row">
            <div class="col-12 col-lg-3">
                <div class="card border-0 mb-3 position-sticky">
                    <div class="card-body pt-20">
                        <div class="mb-4">
                            <h3 class="title-order">Ticket {{ row.uid }}</h3>
                        </div>
                        <div class="mb-4 pt-4 border-top">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <h5>Priority</h5>
                                <h6>{{ row.priority }}</h6>
                            </div>
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <h5>Status</h5>
                                <StatusButton :status="row.status" style="cursor: unset" />
                            </div>
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <h5>Department</h5>
                                <h6>{{ row.department }}</h6>
                            </div>
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <h5>Submission Date</h5>
                                <h6>{{ $timestampToDate(row.created_at) }}</h6>
                            </div>
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <h5>Order/TX ID</h5>
                                <h6>{{ row.department_id }}</h6>
                            </div>
                        </div>
                        <div class="mt-5 mb-3">
                            <h3 class="title-order text-default">Attachments</h3>
                        </div>
                        <div class="pt-3 border-top">
                            <a v-if="row.attachment" :href="row.attachment.original_url" target="_blank" class="attachment">
                                {{ row.attachment.name }}
                            </a>
                            <div v-for="child in row.replies" :key="child.id">
                                <a v-if="child.attachment" :href="child.attachment.original_url" target="_blank"
                                    class="attachment">
                                    {{ child.attachment.name }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-9">
                <div class="card-header card-header-custom bg-transparent border-0">{{ row.subject }}</div>

                <div class="card border-0 mb-3 p-3">

                    <div id="replies" class="replies">
                        <div v-for="child in row.replies" :key="child.id" class="row">
                            <div class="col-8" :class="row.user.id !== child.user.id ? 'offset-4' : null">
                                <div :id="`reply-box-${child.id}`" class="p-3 mb-3"
                                    :class="row.user.id !== child.user.id ? 'support-person-box' : 'customer-person-box'">
                                    <div class="card-header card-header-custom bg-transparent border-0 p-0">{{
                                        child.user.name }}</div>
                                    <div class="submitted-on">{{ $timestampToMonthDay(child.created_at) }} - {{
                                        $timestampToTime12(child.created_at) }}</div>
                                    <div class="card-header card-header-sub bg-transparent border-0 p-0 mt-2">{{
                                        child.message }}</div>
                                    <div class="card-header card-header-sub bg-transparent border-0 p-0 mt-2">
                                        <a v-if="child.attachment" :href="child.attachment.original_url" target="_blank"
                                            class="attachment">
                                            Attachment: {{ child.attachment.name }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <form @submit.prevent="submit">
                                <div id="send-message" class="send-message mt-5">
                                    <div v-if="error.status" class="form-group mb-4">
                                        <div class="alert alert-danger" role="alert">
                                            {{ error.message }}
                                        </div>
                                    </div>
                                    <label for="message" class="form-label">Message</label>
                                    <textarea v-model="input.message" id="message" name="message" class="form-control"
                                        placeholder="Type your message here.." />
                                    <div class="d-flex justify-content-between align-items-center ms-3 mt-3 me-3">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <div @click="focusAttachment" class="cursor-pointer">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                    viewBox="0 0 24 24" fill="none" stroke="#495c77" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round">
                                                    <path
                                                        d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48">
                                                    </path>
                                                </svg>
                                            </div>
                                            <input
                                                @change="(e) => { (e.target.files.length > 0) ? input.attachment = e.target.files[0] : input.attachment = null }"
                                                id="attachment" name="attachment" ref="attachment" type="file"
                                                accept="image/*" class="form-control" />
                                        </div>

                                        <button type="submit" class="message-send text-green">
                                            <span v-show="loading.submit" class="spinner-border spinner-border-sm">
                                            </span>
                                            <span v-show="!loading.submit">
                                                Send
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Spinner from '@/components/Plugin/Spinner.vue'
import StatusButton from '@/components/StatusButton'
import axios from 'axios'

export default {
    name: 'SingleTicketView',
    components: {
        StatusButton,
        Spinner
    },
    created() {
        this.index()
    },
    data() {
        return {
            loading: {
                render: true,
                index: false,
                submit: false
            },
            error: {
                status: false,
                message: null
            },
            input: {
                message: '',
                attachment: null
            },
            row: null,
        }
    },
    methods: {
        async index(url = process.env.VUE_APP_API_URL + 'v1/user/ticket/' + this.$route.params.id) {
            this.loading.index = true

            try {
                const res = await axios.get(url, {
                    headers: this.$authHeader()
                })

                this.row = res.data.data

                this.loading.index = false
                this.loading.render = false

                this.$nextTick(() => {
                    window.scrollTo({
                        top: document.getElementById('send-message').offsetTop,
                        left: 0,
                        behavior: 'smooth'
                    })
                })
            } catch (e) {
                this.loading.index = false
                this.$router.push({ name: 'ticket.index' })

                throw new Error(e)
            }
        },
        async submit() {
            this.loading.submit = true
            this.error.status = false
            this.error.message = null

            if (this.input.message === '') {
                this.error.status = true
                this.error.message = 'The message field is required.'
            } else if (this.input.attachment !== null && this.input.attachment.size > 2 * 1024 * 1024) {
                this.error.status = true
                this.error.message = 'The attachment must not be greater than 2 megabytes.'
            } else {
                try {
                    const res = await axios.post(process.env.VUE_APP_API_URL + 'v1/user/ticket/' + this.$route.params.id, this.input, {
                        headers: this.$authHeader(true)
                    })

                    this.input.message = ''
                    this.input.attachment = null
                    this.$refs.attachment.value = null
                    this.row = res.data.data

                    this.loading.submit = false

                    const replyId = res.data.data.replies[res.data.data.replies.length - 1].id

                    this.$nextTick(() => {
                        window.scrollTo({
                            top: document.getElementById(`reply-box-${replyId}`).offsetTop,
                            left: 0,
                            behavior: 'smooth'
                        })
                    })
                } catch (e) {
                    const err = e.response.data
                    this.error.message = ''

                    if (typeof err.errors !== 'undefined') {
                        const errors = err.errors
                        Object.keys(errors).forEach((errorKey) => {
                            errors[errorKey].forEach((errVal, errIdx) => {
                                this.error.message += (this.error.message === '') ? errVal : ', ' + errVal
                            })
                        })
                    } else if (typeof err.message !== 'undefined') {
                        this.error.message = err.message
                    }

                    this.error.status = true

                    throw new Error(e)
                }
            }

            this.loading.submit = false
        },
        focusAttachment() {
            const el = this.$refs.attachment
            this.$nextTick(() => {
                el.click()
            })
        },
    }
}
</script>

<style scoped>
.replies {
    /*max-height: 50vh;*/
    /*overflow-x: hidden;*/
}

.customer-person-box {
    border-radius: 6px;
}

.customer-person-box .card-header-custom {
    color: var(--text-default);
}

.support-person-box {
    border-radius: 6px;
    background-color: #E7F9F1;
}

.card-header-custom {
    padding: 0 20px 20px 0;
}

.customer-person-box .card-header-custom,
.support-person-box .card-header-custom {
    font-size: 14px;
    line-height: 28px;
}

.card-header-sub {
    line-height: 28px;
}

.submitted-on {
    font-size: 10px;
    font-weight: normal;
    color: var(--text-default);
    letter-spacing: 0.2px;
}

.message-send {
    font-size: 14px;
    font-weight: bold;
    border: unset;
    background: unset;
}

.support-person-box .card-header-custom {
    color: #3ace7c;
}

h3 {
    font-size: 16px;
    font-weight: bold;
    color: var(--text-title);
}

.title-order {
    font-size: 16px;
}

.title-order.text-default {
    font-size: 14px;
    color: var(--text-default);
}

h4 {
    font-size: 16px;
    font-weight: bold;
    color: var(--text-default);
}

h5 {
    font-size: 14px;
    font-weight: normal;
    color: var(--text-default);
    margin: unset;
}

h6 {
    font-size: 12px;
    font-weight: normal;
    color: var(--text-default);
    margin: unset;
}

.attachment {
    font-size: 14px;
    font-weight: normal;
    color: var(--text-default);
    margin: unset;
    text-decoration: none;
    cursor: pointer;
    display: block;
    clear: both;
    margin-bottom: 5px;
}

input[type="number"],
input[type="text"] {
    padding: 0.625rem 0.75rem;
}

select,
input,
textarea {
    font-size: 14px;
    font-weight: normal;
    border-radius: 7px;
    background-color: #f7f7f7;
    border-color: var(--border-default);
    color: var(--text-default);
}

input[type="checkbox"] {
    margin: unset;
}

textarea {
    min-height: 80px;
}

select:focus,
input:focus,
textarea:focus {
    font-size: 14px;
    font-weight: normal;
    border-radius: 7px;
    background-color: #f7f7f7;
    border-color: var(--border-default);
    color: var(--text-default);

    outline: unset;
    box-shadow: unset;
}

input[type="checkbox"] {
    margin-right: 10px;
}

.form-label {
    font-size: 15px;
    font-weight: bold;
    color: var(--text-title);
}

.position-sticky {
    top: 20px;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

input[type=file] {
    text-indent: -105px;
    border: unset;
}
</style>
