<template>
  <div class="row">
    <div class="col-12">
      <h1 class="view-name">{{ title }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleModule',
  props: ['title']
}
</script>

<style scoped>

</style>
