<template>
    <Spinner v-if="loading.render" />
    <div v-else class="subscription-view">
        <div class="vue-modal modal fade" ref="subscribeModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header p-3 border-0">
                        <h5 class="modal-title">
                            <div class="d-flex justify-content-start">
                                <img class="select-box-icon" width="15" src="@/assets/verified.svg">
                                <div class="modal-title-main">Secured Checkout</div>
                            </div>
                            <p class="modal-title-sub">You are connected to a secure encrypted SSL server.</p>
                        </h5>
                        <h3 class="modal-title">{{ $centsToDollar(currentUser.cash) }}</h3>
                    </div>
                    <div class="modal-body p-4">
                        <h3 class="modal-heading mb-3 text-center">Select a payment method</h3>
                        <div class="row">
                            <div v-if="settings && parseInt(settings.stripe) !== 0" class="col-4 no-select">
                                <div @click="fetchStripe()" class="select-box text-center" :class="isSelected('stripe')">
                                    <img class="select-box-icon" width="60" height="30" src="@/assets/stripe.svg"
                                        draggable="false">
                                    <h3>Stripe</h3>
                                </div>
                            </div>
                            <div v-if="settings && parseInt(settings.paypal) !== 0" class="col-4 no-select">
                                <div @click="selected = 'paypal'" class="select-box text-center"
                                    :class="isSelected('paypal')">
                                    <img class="select-box-icon" width="60" height="30" src="@/assets/paypal.svg"
                                        draggable="false">
                                    <h3>Paypal</h3>
                                </div>
                            </div>
                            <div v-if="settings && parseInt(settings.credit_card) !== 0" class="col-4 no-select">
                                <div @click="selected = 'card'" class="select-box text-center" :class="isSelected('card')">
                                    <img class="select-box-icon" width="60" height="30" src="@/assets/master-card.svg"
                                        draggable="false">
                                    <h3>Credit Card</h3>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-8 offset-2">
                                <div v-if="this.error.status" class="form-group mb-4">
                                    <div class="alert alert-danger" role="alert">
                                        {{ this.error.message }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="loading.stripe" class="text-center">
                            <span class="spinner-border spinner-border"></span>
                        </div>
                        <div v-show="selected === 'stripe' && !loading.stripe" class="row mt-5">
                            <div class="col-8 offset-2">
                                <div v-if="!stripe.newPaymentMethod">
                                    <DebitCard :last4="stripe.paymentMethod.last_four" :name="stripe.paymentMethod.name"
                                        :brand="stripe.paymentMethod.brand" :expiry-month="stripe.paymentMethod.exp_month"
                                        :expiry-year="stripe.paymentMethod.exp_year" />

                                    <div class="text-center mt-3">
                                        <div class="d-flex justify-content-between">
                                            <a @click="stripe.newPaymentMethod = true"
                                                class="text-decoration-none cursor-pointer ms-5">Replace Card</a>
                                            <a @click="removePaymentMethod"
                                                class="text-decoration-none cursor-pointer me-5">
                                                <span v-if="loading.remove" class="spinner-border spinner-border-sm"></span>
                                                <span v-else>Remove Card</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="stripe.newPaymentMethod">
                                    <div class="form-group mb-4">
                                        <label for="cash" class="form-label">Card Holder Name</label>
                                        <input v-model="stripe.input.name.value" name="name" id="name" type="text"
                                            class="form-control vue-is-valid">
                                        <div v-if="stripe.input.name.error" class="input-error">
                                            {{ stripe.input.name.error }}
                                        </div>
                                    </div>

                                    <label for="card" class="form-label">Card</label>
                                    <div id="card-element">
                                    </div>
                                    <div v-if="stripe.paymentMethod !== null" class="mt-4">
                                        <a @click="stripe.newPaymentMethod = false"
                                            class="text-decoration-none cursor-pointer">Use
                                            saved card</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer p-3 border-0">
                        <button @click="close" type="button" class="modal-close" data-bs-dismiss="modal">Close</button>
                        <button @click="submit" type="button" class="btn modal-submit">
                            <span v-if="loading.submit" class="spinner-border spinner-border-sm"></span>
                            <span v-else>Subscribe</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h1 class="view-name">Howdy!</h1>
            </div>
        </div>

        <StatsModule v-if="stats" :data="stats" />
        <div class="row">
            <div class="col-12">
                <div class="card border-0 mb-3">
                    <div class="card-header card-header-custom bg-transparent border-0">Choose a membership</div>
                    <div class="card-header card-header-sub bg-transparent border-0">Subscribe to become a member and enjoy
                        flat
                        discount across all services, priority support and fast processing of your orders.
                    </div>
                    <div class="card-body pt-4 pb-4">
                        <div class="row">
                            <div v-for="row in rows" :key="row.id" class="col-12 mb-3 col-lg-4 mb-lg-0">
                                <div class="card card-membership border-0">
                                    <div class="card-body pt-20">
                                        <h5 class="title">{{ row.name }}</h5>
                                        <h5 class="price mb-4">{{ row.price_display }} / {{ row.abbriviation }}</h5>
                                        <h5 class="info mb-4">{{ row.description }}</h5>
                                        <h5 class="discount">{{ row.discount }}%</h5>
                                        <h5 class="discount-info ms-2">Flat Discount</h5>
                                        <a v-if="row.user_subscription === null || row.user_subscription.ends_at !== null"
                                            @click="planId = row.id | this.subscribeModal.show()"
                                            class="btn btn-custom btn-order p-3 w-100 mt-5">
                                            Subscribe
                                        </a>
                                        <a v-if="row.user_subscription !== null && row.user_subscription.ends_at === null"
                                            @click="subscriptionCancel"
                                            class="btn btn-custom btn-order p-3 w-100 mt-5 btn-cancel">
                                            <span v-if="loading.cancel" class="spinner-border spinner-border-sm"></span>
                                            <span v-else>Cancel Subscription</span>
                                        </a>

                                        <h5 v-if="row.user_subscription !== null && row.user_subscription.ends_at !== null"
                                            class="discount-info mt-3 text-center">cancels {{
                                                $timestampToDate(row.user_subscription.ends_at) }}</h5>
                                        <h5 v-if="row.user_subscription !== null && row.user_subscription.ends_at === null"
                                            class="discount-info mt-3 text-center">renews at {{
                                                $timestampToDate(row.user_subscription.renews_at) }}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'
import DebitCard from '@/components/Stripe/DebitCard'
import StatsModule from '@/components/Modules/StatsModule'
import axios from 'axios'
import Spinner from '@/components/Plugin/Spinner.vue'
import { loadStripe } from '@stripe/stripe-js';

export default {
    name: 'SubscriptionView',
    components: {
        StatsModule,
        DebitCard,
        Spinner
    },
    created() {
        if (this.settings && parseInt(this.settings.stripe) !== 0) {
            this.fetchStripe()
        }

        this.index()
    },
    mounted() {
        // this.subscribeModal = new Modal(document.getElementById('subscribeModal'))

        // this.configureStripe()
    },
    data() {
        return {
            loading: {
                render: true,
                stripe: false,
                remove: false,
                submit: false,
                cancel: false,
            },
            subscribeModal: null,
            rows: [],
            stats: null,
            planId: null,
            selected: null,

            stripe: {
                input: {
                    name: {
                        error: null,
                        value: ''
                    },
                },
                paymentMethod: null,
                intent: null,
                newPaymentMethod: true,

                stripe: '',
                elements: '',
                name: '',
                card: '',
            },

            error: {
                status: false,
                message: null
            },
        }
    },
    methods: {
        async index(url = process.env.VUE_APP_API_URL + 'v1/user/plan') {
            try {
                const res = await axios.get(url, {
                    headers: this.$authHeader()
                })

                this.rows = res.data.data

                this.stats = [
                    {
                        id: 1,
                        title: 'Membership',
                        slug: 'subscription',
                        value: res.data.meta.stats.membership + '%',
                        info: 'active discount',
                        svg: 'membershipSubscription'
                    },
                    {
                        id: 2,
                        title: 'Savings',
                        slug: 'savings',
                        value: this.$centsToDollar(res.data.meta.stats.savings),
                        info: 'total saved',
                        svg: 'membershipSavings'
                    },
                    {
                        id: 3,
                        title: 'Joined',
                        slug: 'joined',
                        value: this.$timestampToDate(res.data.meta.stats.joined),
                        info: 'member since',
                        svg: 'membershipJoined'
                    }
                ]

                this.loading.render = false

                this.$nextTick(() => {
                    this.subscribeModal = new Modal(this.$refs.subscribeModal, {
                        backdrop: 'static'
                    });
                });
            } catch (e) {
                throw new Error(e)
            }
        },
        async fetchStripe(url = process.env.VUE_APP_API_URL + 'v1/user/stripe') {
            this.selected = 'stripe'
            this.loading.stripe = true

            try {
                const res = await axios.get(url, {
                    headers: this.$authHeader()
                })

                this.stripe.intent = res.data.meta.intent

                if (res.data.meta.paymentMethod) {
                    this.stripe.paymentMethod = res.data.meta.paymentMethod
                    this.stripe.newPaymentMethod = false
                }

                await this.configureStripe()
            } catch (e) {
                throw new Error(e)
            }

            this.loading.stripe = false
        },
        async removePaymentMethod() {
            this.loading.remove = true

            try {
                await axios.delete(process.env.VUE_APP_API_URL + 'v1/user/stripe/pm', {
                    headers: this.$authHeader()
                })

                this.stripe.paymentMethod = null
                this.stripe.newPaymentMethod = true
            } catch (e) {
                throw new Error(e)
            }

            this.loading.remove = false
        },
        async submit() {
            this.stripe.input.name.error = null
            this.error.status = false
            this.error.message = ''
            this.loading.submit = true

            if (!this.selected) {
                return this.alertError('Select a payment method.')
            }

            try {
                if (this.stripe.newPaymentMethod === false && this.stripe.paymentMethod !== null) {
                    await this.subscriptionUpdate(this.stripe.paymentMethod.id)
                } else {
                    if (this.stripe.input.name.value === '') {
                        this.stripe.input.name.error = 'Card holder name is a required field'
                        this.loading.submit = false
                        return
                    }

                    const { setupIntent, error } = await this.stripe.stripe.confirmCardSetup(
                        this.stripe.intent, {
                        payment_method: {
                            card: this.stripe.card,
                            billing_details: {
                                name: this.stripe.input.name.value
                            }
                        }
                    })

                    if (error) {
                        this.alertError(error.message)
                    } else if (setupIntent.status === 'succeeded') {
                        await this.subscriptionUpdate(setupIntent.payment_method)
                    } else {
                        this.alertError("An error occurred!")
                    }
                }
            } catch (e) {
                throw new Error(e)
            }

            this.loading.submit = false
        },
        async subscriptionCancel() {
            this.loading.cancel = true

            try {
                const res = await axios.delete(process.env.VUE_APP_API_URL + 'v1/user/stripe', {
                    headers: this.$authHeader()
                })

                this.$store.state.user = res.data.meta.user

                this.rows = res.data.meta.rows
                this.stats = [
                    {
                        id: 1,
                        title: 'Membership',
                        slug: 'subscription',
                        value: res.data.meta.stats.membership + '%',
                        info: 'active discount',
                        svg: 'membershipSubscription'
                    },
                    {
                        id: 2,
                        title: 'Savings',
                        slug: 'savings',
                        value: this.$centsToDollar(res.data.meta.stats.savings),
                        info: 'total saved',
                        svg: 'membershipSavings'
                    },
                    {
                        id: 3,
                        title: 'Joined',
                        slug: 'joined',
                        value: this.$timestampToDate(res.data.meta.stats.joined),
                        info: 'member since',
                        svg: 'membershipJoined'
                    }
                ]

            } catch (e) {
                throw new Error(e)
            }

            this.loading.cancel = false
        },

        /*
          Stripe
        */
        async configureStripe() {
            this.stripe.stripe = await loadStripe(this.settings.stripe_publish_key)

            this.stripe.elements = this.stripe.stripe.elements()
            this.stripe.card = this.stripe.elements.create('card', {
                hidePostalCode: true,
                style: {
                    base: {
                        iconColor: '#666EE8',
                        color: '#31325F',
                        fontWeight: 300,
                        fontSize: '15px',

                        '::placeholder': {
                            color: '#CFD7E0'
                        }
                    }
                }
            })

            this.stripe.card.mount('#card-element')
        },
        async subscriptionUpdate(paymentMethodId) {
            try {
                const res = await axios.post(process.env.VUE_APP_API_URL + 'v1/user/stripe', {
                    paymentMethodId: paymentMethodId,
                    planId: this.planId,
                }, {
                    headers: this.$authHeader()
                })

                this.$store.state.user = res.data.meta.user
                // this.stripe.intent = res.data.meta.intent
                // this.stripe.paymentMethod = res.data.meta.paymentMethod
                // this.stripe.newPaymentMethod = false

                // this.success.cash = this.input.cash.value * (10 ** 2)
                // this.success.transaction = res.data.meta.transaction
                // this.stripe.input.name.value = ''
                // this.stripe.card.clear()

                // this.success.status = true

                this.rows = res.data.meta.rows
                this.stats = [
                    {
                        id: 1,
                        title: 'Membership',
                        slug: 'subscription',
                        value: res.data.meta.stats.membership + '%',
                        info: 'active discount',
                        svg: 'membershipSubscription'
                    },
                    {
                        id: 2,
                        title: 'Savings',
                        slug: 'savings',
                        value: this.$centsToDollar(res.data.meta.stats.savings),
                        info: 'total saved',
                        svg: 'membershipSavings'
                    },
                    {
                        id: 3,
                        title: 'Joined',
                        slug: 'joined',
                        value: this.$timestampToDate(res.data.meta.stats.joined),
                        info: 'member since',
                        svg: 'membershipJoined'
                    }
                ]


                this.subscribeModal.hide()
                this.close()
            } catch (e) {
                this.alertError(this.$errorFromException(e))

                throw new Error(e)
            }
        },

        /*
          Helper
        */
        alertError(message) {
            this.error.status = true
            this.error.message = message
            this.loading.submit = false
        },
        isSelected(selected) {
            return (this.selected === selected) ? 'selected' : null
        },
        close() {
            this.stripe.input.name.value = ''
            this.stripe.card.clear()

            this.stripe.input.name.error = null
            this.error.status = false
            this.error.message = ''

            this.planId = null
            this.selected = null
        }
    },
    computed: {
        currentUser() { return this.$store.state.user },
        settings() { return this.$store.state.settings },
        activeSubscription() {
            return this.rows
                ? this.rows.find(row => {
                    return row.user_subscription !== null
                })
                : null
        }
    }
}
</script>

<style scoped>
.card-membership {
    background-color: var(--bg-body) !important;
    border: 3px solid var(--border-default) !important;
}

.card-membership:hover {
    background-color: var(--white) !important;
    box-shadow: 0 30px 100px rgb(0 44 63 / 10%);
}

.title {
    font-size: 18px;
    font-weight: bold;
    color: var(--text-title);
}

.price {
    font-size: 14px;
    font-weight: bold;
    color: var(--text-default);
}

.info {
    font-size: 14px;
    font-weight: normal;
    color: var(--text-default);
    line-height: 22px;
}

.discount {
    font-size: 60px;
    font-weight: bold;
    color: var(--green-default);
}

.discount-info {
    font-size: 12px;
    font-weight: normal;
    color: var(--text-default);
}

.btn-custom {
    font-size: 16px;
    text-transform: uppercase;
    padding: 8px 16px !important;
}
</style>

<style scoped>
.modal-content {
    background-color: var(--bg-body);
}

.modal-dialog {
    max-width: 640px;
}

.modal-header {
    background-color: var(--white);
}

.modal-close {
    margin: 16px 0 0 16px;
    cursor: pointer;
}

.modal-title {
    font-size: 16px;
    font-weight: bold;
    color: var(--green-default);
    /*margin: 0 42px 0 42px;*/
}

.modal-title .modal-title-main {
    margin-left: 7px;
    font-size: 16px;
    font-weight: bold;
    color: var(--text-title);
}

.modal-title .modal-title-sub {
    /*margin-left: 27px;*/
    font-size: 11px;
    font-weight: normal;
    color: var(--text-default);
    margin-bottom: unset;
    margin-top: 2px;
}

.modal-body {
    font-size: 12px;
    font-weight: normal;
    color: var(--text-default);
    /*margin: 20px 42px 51px 42px;*/
    /*padding: unset;*/
}

.modal-body .modal-heading {
    font-size: 14px;
    font-weight: normal;
    color: var(--text-title);
    margin: 0 0 10px 0;
}

.select-box {
    border: 3px solid var(--border-default);
    border-radius: 6px;
    position: relative;
    padding: 10px;
    color: var(--text-default);
    /*background-color: var(--white);*/
    cursor: pointer;
}

.select-box:hover {
    background-color: var(--white);
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}

h3 {
    font-size: 13px;
    margin: unset;
}

.select-box.selected {
    background-color: var(--white);
    border-color: var(--purple-default);
    color: var(--text-default);
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}

.btn-order {
    font-size: 16px;
    width: 100px;
}

.modal-close {
    font-size: 14px;
    color: var(--text-default);
    cursor: pointer;
    text-decoration: none;
    padding: 10px;
    border: unset;
    margin: unset;
    background-color: unset;
}
</style>
