<template>
    <Spinner v-if="loading.render" />
    <div v-else>
        <TitleModule :title="$options.title" />

        <div class="vue-view">
            <div class="card border-0 mb-3">

                <CardHeaderModule :redirect="{ name: 'New Ticket', slug: 'ticket.store' }" :status="status"
                    :selected="apiParams.status" :searchBox="searchBox" :searchValue="apiParams.search"
                    @moduleClick="(val) => { dispatchChangeTab(val) }" @modalSearch="(val) => { dispatchSearch(val) }"
                    @modalSearchBox="(val) => { dispatchSearchBox(val) }" />

                <div class="card-body">
                    <div v-if="loading.index" class="d-flex justify-content-center align-items-center"
                        style="min-height: 431px;">
                        <div class="spinner-border" role="status">
                        </div>
                    </div>
                    <div v-else-if="rows.length <= 0" class="d-flex justify-content-center align-items-center"
                        style="min-height: 431px;">
                        <h5>No data found</h5>
                    </div>
                    <div v-else>
                        <Datatable :columns="this.columns">
                            <tbody>
                                <tr v-for="row in rows" :key="row.id">
                                    <td>
                                        <router-link :to="{ name: 'ticket.show', params: { id: row.id } }">
                                            {{ row.subject }}
                                        </router-link>
                                    </td>
                                    <td>{{ row.department }}</td>
                                    <td>
                                        <router-link :to="{ name: 'ticket.show', params: { id: row.id } }">
                                            {{ row.uid }}
                                        </router-link>
                                    </td>
                                    <td>{{ row.priority }}</td>
                                    <td>{{ $timestampToDate(row.created_at) }}<br><span>{{
                                        $timestampToTime24(row.created_at) }}</span></td>
                                    <td>{{ $timestampToDate(row.updated_at) }}<br><span>{{
                                        $timestampToTime24(row.updated_at) }}</span></td>
                                    <td>
                                        <StatusButton :status="row.status" />
                                    </td>
                                    <td class="text-center">
                                        <div class="btn-group dropstart">
                                            <svg class="action" data-bs-toggle="dropdown" aria-expanded="false" width="24"
                                                height="24" fill="currentColor" viewBox="0 0 16 16">
                                                <path
                                                    d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z">
                                                </path>
                                            </svg>
                                            <ul class="dropdown-menu">
                                                <router-link :to="{ name: 'ticket.show', params: { id: row.id } }"
                                                    class="dropdown-item">
                                                    Reply
                                                </router-link>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Datatable>
                        <Pagination :pagination="pagination" @prev="index(pagination.prevPageUrl)"
                            @next="index(pagination.nextPageUrl)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StatusButton from '@/components/StatusButton'
import Datatable from '@/components/Form/Datatable'
import Pagination from '@/components/Form/Pagination'
import CardHeaderModule from '@/components/Modules/CardHeaderModule'
import TitleModule from '@/components/Modules/TitleModule'
import axios from 'axios'
import Spinner from '@/components/Plugin/Spinner.vue'

export default {
    title: 'Tickets',
    name: 'TicketsView',
    components: {
        TitleModule,
        CardHeaderModule,
        StatusButton,
        Datatable,
        Pagination,
        Spinner
    },
    created() {
        this.index()
    },
    data() {
        return {
            loading: {
                render: true,
                index: false
            },
            status: [
                {
                    id: 0,
                    title: 'All',
                    slug: 'all',
                    value: null
                },
                {
                    id: 1,
                    title: 'Pending',
                    slug: 'pending',
                    value: 1
                },
                {
                    id: 2,
                    title: 'Answered',
                    slug: 'answered',
                    value: 2
                },
                {
                    id: 3,
                    title: 'Closed',
                    slug: 'closed',
                    value: 3
                }
            ],
            columns: [
                {
                    label: 'Subject',
                    name: 'subject'
                },
                {
                    label: 'Department',
                    name: 'department'
                },
                {
                    label: 'Ticket Number',
                    name: 'uid'
                },
                {
                    label: 'Priority',
                    name: 'priority'
                },
                {
                    label: 'Submission Date',
                    name: 'submission_date'
                },
                {
                    label: 'Last Update',
                    name: 'updated_at'
                },
                {
                    width: '80px',
                    label: 'Status',
                    name: 'status'
                },
                {
                    width: '80px',
                    label: 'Action',
                    name: 'action'
                }
            ],
            rows: null,
            pagination: {
                lastPage: '',
                currentPage: '',
                total: '',
                lastPageUrl: '',
                nextPageUrl: '',
                prevPageUrl: '',
                from: '',
                to: ''
            },
            apiParams: {
                search: '',
                status: null
            },
            searchBox: false
        }
    },
    methods: {
        async index(url = process.env.VUE_APP_API_URL + 'v1/user/ticket') {
            this.loading.index = true

            try {
                const res = await axios.get(url, {
                    headers: this.$authHeader(),
                    params: this.apiParams
                })

                this.rows = res.data.data.data

                this.pagination.lastPage = res.data.data.meta.last_page
                this.pagination.currentPage = res.data.data.meta.current_page
                this.pagination.total = res.data.data.meta.total
                this.pagination.lastPageUrl = res.data.data.links.last
                this.pagination.nextPageUrl = res.data.data.links.next
                this.pagination.prevPageUrl = res.data.data.links.prev
                this.pagination.from = res.data.data.meta.from
                this.pagination.to = res.data.data.meta.to

                this.loading.index = false
                this.loading.render = false
            } catch (e) {
                this.loading.index = false

                throw new Error(e)
            }
        },
        dispatchChangeTab(tab = null) {
            this.apiParams.status = tab
            this.index()
        },
        dispatchSearch(data) {
            if (this.apiParams.search !== data) {
                this.apiParams.search = data
                this.index()
            }
        },
        dispatchSearchBox(value) {
            this.searchBox = value
            if (this.searchBox === false && this.apiParams.search !== '') {
                this.apiParams.search = ''
                this.index()
            }
        },
    }
}
</script>
