import axios from 'axios'

const user = JSON.parse(localStorage.getItem('user'))
const initialState = user
    ? {
        authenticated: true,
        user: user
    }
    : {
        authenticated: false,
        user: null
    }

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        referred({ state }, data) {
            return axios
                .post(process.env.VUE_APP_API_URL + 'referred', data, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(res => {
                    return Promise.resolve(res.data)
                })
                .catch(err => {
                    return Promise.reject(err.response.data)
                })
        },
        register({ commit }, data) {
            return axios
                .post(process.env.VUE_APP_API_URL + 'register', data, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(res => {
                    if (!res.data.token) {
                        console.log('on success token not found')
                        return Promise.reject(res.data)
                    }

                    commit('loginSuccess', {
                        id: res.data.id,
                        token: res.data.token
                    })
                    return Promise.resolve(res.data)
                })
                .catch(err => {
                    commit('loginFailure')
                    return Promise.reject(err.response.data)
                })
        },
        login({ commit }, data) {
            return axios
                .post(process.env.VUE_APP_API_URL + 'login', {
                    email: data.email,
                    password: data.password
                }, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(res => {
                    if (!res.data.token) {
                        console.log('on success token not found')
                        return Promise.reject(res.data)
                    }

                    commit('loginSuccess', {
                        id: res.data.id,
                        token: res.data.token
                    })
                    return Promise.resolve(res.data)
                })
                .catch(err => {
                    commit('loginFailure')
                    return Promise.reject(err.response.data)
                })
        },
        loginAsUser({ commit }, data) {
            commit('loginSuccess', {
                id: data.id,
                token: data.token
            })
        },
        verify({ commit }, data) {
            return axios
                .post(process.env.VUE_APP_API_URL + 'auth/verify', data, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(res => {
                    return Promise.resolve(res.data)
                })
                .catch(err => {
                    return Promise.reject(err.response.data)
                })
        },
        forget({ commit }, data) {
            return axios
                .post(process.env.VUE_APP_API_URL + 'auth/forget', data, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(res => {
                    return Promise.resolve(res.data)
                })
                .catch(err => {
                    return Promise.reject(err.response.data)
                })
        },
        reset({ commit }, data) {
            return axios
                .post(process.env.VUE_APP_API_URL + 'auth/reset', data, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(res => {
                    return Promise.resolve(res.data)
                })
                .catch(err => {
                    return Promise.reject(err.response.data)
                })
        },
        logout({ commit }) {
            commit('logout')
        }
    },
    mutations: {
        loginSuccess(state, data) {
            state.authenticated = true
            state.user = data
            localStorage.setItem('user', JSON.stringify(data))
        },
        loginFailure(state) {
            state.authenticated = false
            state.user = null
        },
        logout(state) {
            state.authenticated = false
            state.user = null
            localStorage.removeItem('user')
        }
    }
}
