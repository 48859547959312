<template>
    <div class="vue-modal modal fade" id="checkoutModal" tabindex="-1" aria-labelledby="checkoutModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header p-3 border-0">
                    <h5 class="modal-title">
                        <div class="d-flex justify-content-start">
                            <img class="select-box-icon" width="15" src="@/assets/verified.svg">
                            <div class="modal-title-main">Secured Checkout</div>
                        </div>
                        <p class="modal-title-sub">You are connected to a secure encrypted SSL server.</p>
                    </h5>
                    <h3 class="modal-title">Funds Available: {{ $centsToDollar(currentUser.cash) }}</h3>
                </div>
                <div class="modal-body p-4">
                    <div class="row">
                        <div class="col-8 offset-2">
                            <div v-if="success.status">
                                <div class="modal-body text-center">
                                    <div class="icon-box mb-3">
                                        <svg viewBox="0 0 100 100" width="50px" height="50px">
                                            <g id="surface112323266">
                                                <path
                                                    style=" stroke:none;fill-rule:nonzero;fill:var(--purple-default);fill-opacity:1;"
                                                    d="M 83.875 17.25 C 82.546875 17.296875 81.328125 18 80.625 19.125 L 43 76.6875 L 18.625 55.625 C 17.578125 54.539062 16.007812 54.132812 14.5625 54.585938 C 13.125 55.03125 12.054688 56.25 11.804688 57.734375 C 11.554688 59.226562 12.15625 60.726562 13.375 61.625 L 41.25 85.75 C 42.125 86.492188 43.28125 86.820312 44.414062 86.65625 C 45.554688 86.484375 46.5625 85.835938 47.1875 84.875 L 87.375 23.5 C 88.234375 22.242188 88.304688 20.617188 87.5625 19.289062 C 86.820312 17.96875 85.390625 17.179688 83.875 17.25 Z M 83.875 17.25 " />
                                            </g>
                                        </svg>
                                    </div>
                                    <h4>Deposited!</h4>
                                    <p>
                                        Your deposit of {{ $centsToDollar(success.cash) }} has been
                                        placed successfully agains transaction <a href="#"
                                            @click="redirectToTransactionScreen">{{ success.transaction }}</a>.</p>
                                    <div class="text-center mt-3">
                                        <div class="d-flex justify-content-between">
                                            <a @click="success.status = false"
                                                class="text-decoration-none cursor-pointer ms-5">Deposit More</a>
                                            <a @click="redirectToOrderScreen"
                                                class="text-decoration-none cursor-pointer me-5">Order Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-show="!success.status">
                                <div v-if="this.error.status" class="form-group mb-4">
                                    <div class="alert alert-danger" role="alert">
                                        {{ this.error.message }}
                                    </div>
                                </div>
                                <div class="form-group mb-4">
                                    <label for="cash" class="form-label">Cash</label>
                                    <input v-model="input.cash.value" name="cash" id="cash" type="number"
                                        class="form-control vue-is-valid" min="0" step=".01">
                                    <div v-if="input.cash.error" class="input-error">
                                        {{ input.cash.error }}
                                    </div>
                                </div>
                                <div v-if="!newPaymentMethod">
                                    <DebitCard :last4="paymentMethod.last_four" :name="paymentMethod.name"
                                        :brand="paymentMethod.brand" :expiry-month="paymentMethod.exp_month"
                                        :expiry-year="paymentMethod.exp_year" />

                                    <div class="text-center mt-3">
                                        <div class="d-flex justify-content-between">
                                            <a @click="newPaymentMethod = true"
                                                class="text-decoration-none cursor-pointer ms-5">Replace Card</a>
                                            <a @click="removePaymentMethod"
                                                class="text-decoration-none cursor-pointer me-5">
                                                <span v-if="loading.remove" class="spinner-border spinner-border-sm"></span>
                                                <span v-else>Remove Card</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="newPaymentMethod">
                                    <div class="form-group mb-4">
                                        <label for="cash" class="form-label">Card Holder Name</label>
                                        <input v-model="input.name.value" name="name" id="name" type="text"
                                            class="form-control vue-is-valid">
                                        <div v-if="input.name.error" class="input-error">
                                            {{ input.name.error }}
                                        </div>
                                    </div>

                                    <label for="card" class="form-label">Card</label>
                                    <div id="card-element">
                                    </div>
                                    <div v-if="paymentMethod !== null" class="mt-4">
                                        <a @click="newPaymentMethod = false" class="text-decoration-none cursor-pointer">Use
                                            saved card</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="loading.render" class="createdLoading">
                        <span class="spinner-border spinner-border"></span>
                    </div>
                </div>
                <div class="modal-footer p-3 border-0">
                    <button @click="$emit('close')" type="button" class="modal-close" data-bs-dismiss="modal">Close</button>
                    <button v-if="!success.status" @click="submit" class="btn modal-submit">
                        <span v-if="loading.index" class="spinner-border spinner-border-sm">
                        </span>
                        <span v-else>
                            Deposit
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Form } from 'vee-validate'
import DebitCard from '@/components/Stripe/DebitCard'
import Modal from 'bootstrap/js/dist/modal'
import SimpleInput from '@/components/Form/SimpleInput'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js';

export default {
    name: 'CheckoutComponent',
    components: {
        Form,
        SimpleInput,
        DebitCard
    },
    created() {
        this.index()
    },
    mounted() {
        this.checkoutModal = new Modal(document.getElementById('checkoutModal'), {
            backdrop: 'static'
        })
        this.checkoutModal.show()
        this.configureStripe()
    },
    data() {
        return {
            loading: {
                render: true,
                index: false,
                remove: false
            },
            error: {
                status: false,
                message: null
            },
            input: {
                cash: {
                    error: null,
                    value: 0
                },
                name: {
                    error: null,
                    value: ''
                },
            },
            paymentMethod: null,
            intent: null,
            newPaymentMethod: true,
            success: {
                status: false,
                cash: 0,
                transaction: null,
            },

            checkoutModal: null,


            stripe: '',
            elements: '',
            card: '',
            intentToken: ''
        }
    },
    methods: {
        async index(url = process.env.VUE_APP_API_URL + 'v1/user/stripe') {
            this.loading.index = true

            try {
                const res = await axios.get(url, {
                    headers: this.$authHeader()
                })

                this.intent = res.data.meta.intent

                if (res.data.meta.paymentMethod) {
                    this.paymentMethod = res.data.meta.paymentMethod
                    this.newPaymentMethod = false
                }

                this.loading.index = false
                this.loading.render = false
            } catch (e) {
                this.loading.index = false

                throw new Error(e)
            }
        },
        alertError(message) {
            this.error.status = true
            this.error.message = message
            this.loading.index = false
        },

        /*
          Stripe
        */
        async configureStripe() {
            this.stripe = await loadStripe(this.settings.stripe_publish_key)

            this.elements = this.stripe.elements()
            this.card = this.elements.create('card', {
                hidePostalCode: true,
                style: {
                    base: {
                        iconColor: '#666EE8',
                        color: '#31325F',
                        fontWeight: 300,
                        fontSize: '15px',

                        '::placeholder': {
                            color: '#CFD7E0'
                        }
                    }
                }
            })

            this.card.mount('#card-element')
        },

        async removePaymentMethod() {
            this.loading.remove = true

            try {
                await axios.delete(process.env.VUE_APP_API_URL + 'v1/user/stripe/pm', {
                    headers: this.$authHeader()
                })

                this.paymentMethod = null
                this.newPaymentMethod = true
            } catch (e) {
                throw new Error(e)
            }

            this.loading.remove = false
        },

        async submit() {
            this.input.cash.error = null
            this.input.name.error = null
            this.error.status = false
            this.error.message = null

            if (this.input.cash.value === '') {
                this.input.cash.error = 'Cash is a required field'
                return
            }

            this.input.cash.value = parseFloat(this.input.cash.value)

            if (isNaN(this.input.cash.value) || !Number.isFinite(this.input.cash.value)) {
                this.input.cash.error = 'Invalid cash value'
                return
            }
            if (this.input.cash.value < 1) {
                this.input.cash.error = 'Cash must be at least $1'
                return
            }
            if (!Number.isInteger(this.input.cash.value * 100)) {
                this.input.cash.error = 'Cash must have 2 digits after the decimal point or less'
                return
            }

            this.loading.index = true


            try {
                if (this.newPaymentMethod === false && this.paymentMethod !== null) {
                    await this.purchase(this.paymentMethod.id)
                } else {
                    if (this.input.name.value === '') {
                        this.input.name.error = 'Card holder name is a required field'
                        this.loading.index = false
                        return
                    }

                    const { setupIntent, error } = await this.stripe.confirmCardSetup(
                        this.intent, {
                        payment_method: {
                            card: this.card,
                            billing_details: {
                                name: this.input.name.value
                            }
                        }
                    })

                    if (error) {
                        this.alertError(error.message)
                    } else if (setupIntent.status === 'succeeded') {
                        await this.purchase(setupIntent.payment_method)
                    } else {
                        this.alertError("An error occurred!")
                    }
                }
            } catch (e) {
                throw new Error(e)
            }

            this.loading.index = false
        },
        async purchase(paymentMethodId) {
            try {
                const res = await axios.post(process.env.VUE_APP_API_URL + 'v1/user/stripe', {
                    paymentMethodId: paymentMethodId,
                    cash: this.input.cash.value * (10 ** 2),
                }, {
                    headers: this.$authHeader()
                })

                this.$store.state.user = res.data.meta.user
                this.intent = res.data.meta.intent
                this.paymentMethod = res.data.meta.paymentMethod
                this.newPaymentMethod = false

                this.success.cash = this.input.cash.value * (10 ** 2)
                this.success.transaction = res.data.meta.transaction
                this.input.cash.value = 0
                this.input.name.value = ''
                this.card.clear()

                this.success.status = true
            } catch (e) {
                this.alertError(this.$errorFromException(e))

                throw new Error(e)
            }
        },
        redirectToOrderScreen() {
            this.checkoutModal.hide()
            this.$router.push({ name: 'order.store' })
        },
        redirectToTransactionScreen() {
            this.checkoutModal.hide()
            this.$router.push({ name: 'transaction.index' })
        }
    },
    watch: {
        'checkoutModal._isShown': {
            deep: true,
            handler(val) {
                if (val === false) {
                    this.$emit('close')
                }
            }
        }
    },
    computed: {
        currentUser() { return this.$store.state.user },
        settings() { return this.$store.state.settings }
    }
}
</script>

<style scoped>
.createdLoading {
    top: 0;
    left: 0;
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-body);
}

.icon-box {
    color: #fff;
    width: 95px;
    height: 95px;
    display: inline-block;
    border-radius: 50%;
    z-index: 9;
    border: 5px solid var(--purple-default);
    padding: 15px;
    text-align: center;
}

.icon-box i {
    font-size: 64px;
    margin: -4px 0 0 -4px;
}
</style>
