<template>
  <nav aria-label="Page navigation example" class="d-flex justify-content-between align-items-center p-0">
    <span class="page-stats">{{pagination.from}} - {{pagination.to}} of {{pagination.total}}</span>
    <ul class="pagination m-0">
      <li
        :class="(pagination.currentPage === 1) ? 'disabled' : null"
        class="page-item cursor-pointer no-select">
        <a
          @click="$emit('prev');"
          class="page-link"
          tabindex="-1"
          aria-disabled="true">
          Previous
        </a>
      </li>
      <li
        :class="(pagination.currentPage === pagination.lastPage) ? 'disabled' : null"
        class="page-item cursor-pointer no-select">
        <a
          @click="$emit('next');"
          class="page-link"
          tabindex="-1"
          aria-disabled="true">
          Next
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Pagination',
  props: ['pagination', 'client', 'filtered']
}
</script>
