import { createStore } from 'vuex'
import { setting } from './setting.module'
import { auth } from './auth.module'
import { users } from './user.module'
import { notification } from './notification.module'
import { coupon } from './coupon.module'
import { announcement } from './announcement.module'
import axios from 'axios'

export default createStore({
    state: {
        featureAnnouncement: null,
        categories: [],
        coupons: [],
        user: null,
        settings: null,
    },
    actions: {
        async fetchSetting({ state }, context) {
            try {
                const res = await axios.get(process.env.VUE_APP_API_URL + 'v1/user/setting', {
                    headers: context.$authHeader()
                })

                state.featureAnnouncement = res.data.data.featureAnnouncement
                state.categories = res.data.data.categories
                state.coupons = res.data.data.coupons
                state.user = res.data.data.user
                state.settings = res.data.data.settings

                return Promise.resolve(res)
            } catch (e) {
                return Promise.reject(e)
            }
        }
    },
    modules: {
        setting,
        auth,
        users,
        notification,
        coupon,
        announcement
    }
})
