<template>
  <div
    v-if="rows.length > 0">
    <div class="table-responsive">
      <table class="table align-middle">
        <thead>
        <tr>
          <th scope="col">TID</th>
          <th scope="col">Date</th>
          <th scope="col">Method</th>
          <th scope="col">Amount</th>
          <th scope="col">Invoice</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="row in rows"
          :key="row.id">
          <td>{{ row.uid }}</td>
          <td>{{ timestampToDate(row.created_at) }}<br><span>{{ timestampToTime24(row.created_at) }}</span></td>
          <td>{{ row.method }}<br><span>{{ row.method_id }}</span></td>
          <td>{{ this.centsToDollar(row.amount) }}</td>
          <td>
            <img
              width="12"
              height="12"
              src="@/assets/ic-download.svg">
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <router-link
      :to="{ name: 'fund.index' }"
      class="d-flex justify-content-end text-decoration-none pe-3 anchor"
      aria-current="page">
      View All
    </router-link>
  </div>
  <div
    v-else
    class="d-flex justify-content-center align-items-center" style="min-height: 450px;">
    <h5>No data found</h5>
  </div>
</template>

<script>
export default {
  name: 'RecentFunds',
  props: ['rows'],
  components: {
  },
  methods: {
    /* Static global helpers */
    centsToDollar (cents) {
      return this.$centsToDollar(cents)
    },
    timestampToDate (timestamp) {
      return this.$timestampToDate(timestamp)
    },
    timestampToTime24 (timestamp) {
      return this.$timestampToTime24(timestamp)
    }
  }
}
</script>

<style scoped>

</style>
