import axios from 'axios'
import authHeader from '@/store/auth.header'

export const users = {
    namespaced: true,
    state: {
        row: null
    },
    actions: {
        show({ commit }, id) {
            return axios
                .get(process.env.VUE_APP_API_URL + 'user/' + id, {
                    headers: authHeader()
                })
                .then(res => {
                    commit('populateRow', res.data)
                    return Promise.resolve(res.data)
                })
                .catch(err => {
                    return Promise.reject(err.response)
                })
        },
        update({ commit }, { id, data }) {
            console.log('data', data)
            return axios
                .post(`${process.env.VUE_APP_API_URL}user/${id}`, data, {
                    headers: authHeader(true)
                })
                .then(res => {
                    commit('populateRow', res.data)
                    return Promise.resolve(res)
                })
                .catch(err => {
                    return Promise.reject(err.response.data)
                })
        },
        lazyUpdate({ commit }, data) {
            commit('populateRow', data)
        }
    },
    mutations: {
        populateRow(state, data) {
            state.row = data
        },
        decCash(state, value) {
            state.row.cash -= value
        }
    }
}
