<template>
    <Spinner v-if="loading.render" />
    <div v-else>
        <TitleModule :title="$options.title" />

        <StatsModule v-if="stats" :data="stats" @moduleClick="(val) => { dispatchChangeTab(val) }" />

        <div class="row">
            <div class="col-12">
                <div class="card border-0 mb-3">
                    <div class="card-header card-header-custom bg-transparent border-0">How do you want to pay?</div>
                    <div class="card-header card-header-sub bg-transparent border-0">Please select a payment method to add
                        funds to your account.</div>
                    <div class="card-body pt-4 pb-4">
                        <div class="row mb-4">
                            <div v-if="settings && parseInt(settings.stripe) !== 0" class="col-4 no-select">
                                <div @click="changeSelected('stripe')"
                                    class="select-box d-flex justify-content-between align-items-center"
                                    :class="isSelected('stripe')">
                                    <div>
                                        <h3>Stripe</h3>
                                        <p>Pay using Visa, Mastercard, AMX</p>
                                    </div>
                                    <img class="select-box-icon" width="60" height="30" src="@/assets/stripe.svg"
                                        draggable="false">
                                </div>
                                <StripeCheckoutComponent v-if="isSelected('stripe')" @close="changeSelected(null)" />
                            </div>
                            <div v-if="settings && parseInt(settings.paypal) !== 0" class="col-4 no-select">
                                <div @click="changeSelected('Paypal')"
                                    class="select-box d-flex justify-content-between align-items-center"
                                    :class="isSelected('Paypal')">
                                    <div>
                                        <h3>Paypal</h3>
                                        <p>Pay using Visa, Mastercard, AMX</p>
                                    </div>
                                    <img class="select-box-icon" width="80" height="30" src="@/assets/paypal.svg"
                                        draggable="false">
                                </div>
                            </div>
                            <div v-if="settings && parseInt(settings.credit_card) !== 0" class="col-4 no-select">
                                <div @click="changeSelected('CreditCard')"
                                    class="select-box d-flex justify-content-between align-items-center"
                                    :class="isSelected('CreditCard')">
                                    <div>
                                        <h3>Credit Cards</h3>
                                        <p>Pay using Visa, Mastercard, AMX</p>
                                    </div>
                                    <img class="select-box-icon" width="60" height="30" src="@/assets/master-card.svg"
                                        draggable="false">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StripeCheckoutComponent from '@/components/Modals/StripeCheckoutComponent'
import TitleModule from '@/components/Modules/TitleModule'
import StatsModule from '@/components/Modules/StatsModule'
import Spinner from '@/components/Plugin/Spinner.vue'
import axios from 'axios'

export default {
    title: 'Funds',
    name: 'FundsView',
    components: {
        StatsModule,
        TitleModule,
        StripeCheckoutComponent,
        Spinner
    },
    created() {
        this.index()
    },
    data() {
        return {
            loading: {
                render: true,
                index: false
            },
            stats: null,
            selected: null
        }
    },
    methods: {
        async index(url = process.env.VUE_APP_API_URL + 'v1/user/fund') {
            this.loading.index = true

            try {
                const res = await axios.get(url, {
                    headers: this.$authHeader()
                })

                this.stats = [
                    {
                        id: 1,
                        title: 'Available Funds',
                        slug: 'funds_available',
                        value: this.$centsToDollar(res.data.meta.stats.funds_available),
                        info: 'available credit',
                        svg: 'fundsAvailable'
                    },
                    {
                        id: 2,
                        title: 'Total Added',
                        slug: 'total',
                        value: this.$centsToDollar(res.data.meta.stats.total_funds_added),
                        info: 'since joined',
                        svg: 'fundsTotal'
                    },
                    {
                        id: 3,
                        title: 'Transactions',
                        slug: 'transactions',
                        value: res.data.meta.stats.transactions,
                        info: 'total invoices',
                        svg: 'fundsTransactions'
                    }
                ]

                this.loading.index = false
                this.loading.render = false
            } catch (e) {
                this.loading.index = false

                throw new Error(e)
            }
        },
        dispatchChangeTab(tab = null) {
            switch (tab) {
                case 3:
                    this.$router.push({ name: 'transaction.index' })
                    break
            }
        },

        changeSelected(selected) {
            this.selected = selected
        },
        isSelected(selected) {
            return (this.selected === selected) ? 'selected' : null
        }
    },
    computed: {
        settings() { return this.$store.state.settings }
    }
}
</script>

<style scoped>
.select-box {
    border: 3px solid var(--border-default);
    border-radius: 6px;
    position: relative;
    padding: 20px;
    color: var(--text-default);
    background-color: #f7f7f7;
    cursor: pointer;
}

.select-box:hover {
    background-color: var(--white);
    box-shadow: 0px 6px 6px rgb(0 0 0 / 4%), 0px 0px 42px rgb(0 0 0 / 4%);
    transition: 0.3s;
}

.select-box.active {
    background-color: var(--white);
    border: 3px solid var(--purple-default);
    transition: 0.3s;
}

.select-box h3 {
    font-size: 14px;
    font-weight: bold;
    color: var(--text-title);
}

.select-box p {
    font-size: 12px;
    font-weight: normal;
    color: var(--text-default);
    margin: unset;
}

select,
input,
textarea {
    font-size: 14px;
    font-weight: normal;
    border-radius: 7px;
    background-color: #f7f7f7;
    border-color: var(--border-default);
    color: var(--text-default);
}

.nav-link {
    font-size: 14px;
    color: var(--text-default);
    padding: 0;
    margin: 0.5rem 0 0 0;
}

.nav-info {
    font-size: 11px;
    margin: 0;
}

.nav-link.active {
    /*border-bottom: 2px solid var(--purple-default);*/
    font-weight: bold;
    color: var(--green-default);
}
</style>
