const moment = require('moment')
require('moment/locale/en-gb')
moment.locale('en-gb')

const momentTimezone = require('moment-timezone')

const errorFromException = (e) => {
    console.error('exception', e)
    if (e.response.data.error !== undefined) {
        return e.response.data.error
    }
    if (e.message !== undefined) {
        return e.message
    }
    if (e.error !== undefined) {
        return e.error
    }
};

const authHeader = (hasFile = false) => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return {
            Accept: 'application/json',
            'Content-Type': hasFile ? 'multipart/form-data' : 'application/json',
            Authorization: `Bearer ${user.token}`
        };
    } else {
        return {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*'
        };
    }
};

const centsToDollar = (cents) => {
    if (!cents) {
        return '$0';
    }
    return (parseInt(cents) / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
};

const timestampToDaysRemaining = (timestamp) => {
    if (moment(timestamp, 'X', true).isValid()) {
        return moment.duration(moment.unix(timestamp).diff(moment().startOf('day'))).humanize();
    } else {
        return moment.duration(moment(timestamp).diff(moment().startOf('day'))).humanize();
    }
};

const timestampToDate = (timestamp) => {
    if (moment(timestamp, 'X', true).isValid()) {
        return moment.unix(timestamp).format('MMM D, YYYY');
    } else {
        return moment(timestamp).format('MMM D, YYYY');
    }
};

const timestampToMonthDay = (timestamp) => {
    if (moment(timestamp, 'X', true).isValid()) {
        return moment.unix(timestamp).format('D MMMM');
    } else {
        return moment(timestamp).format('D MMMM');
    }
};

const timestampToTime12 = (timestamp) => {
    if (moment(timestamp, 'X', true).isValid()) {
        return moment.unix(timestamp).format('h:mm a');
    } else {
        return moment(timestamp).format('h:mm a');
    }
};

const timestampToTime24 = (timestamp) => {
    if (moment(timestamp, 'X', true).isValid()) {
        return moment.unix(timestamp).format('H:mm');
    } else {
        return moment(timestamp).format('H:mm');
    }
};

const timezoneWithAbbr = (timestamp) => {
    const zoneName = momentTimezone.tz.guess();
    const timeZoneName = Intl.DateTimeFormat('en', {
        timeZoneName: 'short',
        timeZone: zoneName
    })
        .formatToParts()
        .find((i) => i.type === 'timeZoneName').value;

    return `${zoneName} (${timeZoneName})`;
};

export default {
    install(app) {
        app.config.globalProperties.$errorFromException = errorFromException;
        app.config.globalProperties.$authHeader = authHeader;
        app.config.globalProperties.$centsToDollar = centsToDollar;
        app.config.globalProperties.$timestampToDaysRemaining = timestampToDaysRemaining;
        app.config.globalProperties.$timestampToDate = timestampToDate;
        app.config.globalProperties.$timestampToMonthDay = timestampToMonthDay;
        app.config.globalProperties.$timestampToTime12 = timestampToTime12;
        app.config.globalProperties.$timestampToTime24 = timestampToTime24;
        app.config.globalProperties.$timezoneWithAbbr = timezoneWithAbbr;
    }
};
