<template>
  <div
    v-if="rows.length > 0">
    <div
      class="table-responsive">
      <table class="table align-middle">
        <thead>
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Details</th>
          <th scope="col">Cost</th>
          <th scope="col">Start Count</th>
          <th scope="col" style="width: 80px">Status</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="row in rows"
          :key="row.id">
          <td>{{ timestampToDate(row.created_at) }}<br><span>{{ timestampToTime24(row.created_at) }}</span></td>
          <td>
            {{ row.service.name }}
            <br>
            <span class="d-flex align-items-center">
              {{ row.url }}
              <a :href="row.url" target="_blank"><IconCopy class="ms-2 cursor-pointer"/></a>
            </span>
          </td>
          <td>{{ this.centsToDollar(row.cost) }}</td>
          <td>{{ row.start_count }}</td>
          <td>
            <StatusButton :status="row.status"/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <router-link
      :to="{ name: 'order.index' }"
      class="d-flex justify-content-end text-decoration-none pe-3 anchor"
      aria-current="page">
      View All
    </router-link>
  </div>
  <div
    v-else
    class="d-flex justify-content-center align-items-center" style="min-height: 450px;">
    <h5>No data found</h5>
  </div>
</template>

<script>
import IconCopy from '@/components/Svg/IconCopy.vue'
import StatusButton from '@/components/StatusButton'

export default {
  name: 'RecentOrders',
  props: ['rows'],
  components: {
    IconCopy,
    StatusButton
  },
  methods: {
    /* Static global helpers */
    centsToDollar (cents) {
      return this.$centsToDollar(cents)
    },
    timestampToDate (timestamp) {
      return this.$timestampToDate(timestamp)
    },
    timestampToTime24 (timestamp) {
      return this.$timestampToTime24(timestamp)
    }
  }
}
</script>

<style scoped>

</style>
