<template>
    <div id="successModal" class="modal fade">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <div class="icon-box">
                        <svg viewBox="0 0 100 100" width="50px" height="50px">
                            <g id="surface112323266">
                                <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
                                    d="M 83.875 17.25 C 82.546875 17.296875 81.328125 18 80.625 19.125 L 43 76.6875 L 18.625 55.625 C 17.578125 54.539062 16.007812 54.132812 14.5625 54.585938 C 13.125 55.03125 12.054688 56.25 11.804688 57.734375 C 11.554688 59.226562 12.15625 60.726562 13.375 61.625 L 41.25 85.75 C 42.125 86.492188 43.28125 86.820312 44.414062 86.65625 C 45.554688 86.484375 46.5625 85.835938 47.1875 84.875 L 87.375 23.5 C 88.234375 22.242188 88.304688 20.617188 87.5625 19.289062 C 86.820312 17.96875 85.390625 17.179688 83.875 17.25 Z M 83.875 17.25 " />
                            </g>
                        </svg>
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-hidden="true">&times;</button>
                </div>
                <div class="modal-body text-center">
                    <h4>Great!</h4>
                    <p>Your order has been placed successfully.</p>
                    <button class="btn btn-success" data-bs-dismiss="modal">
                        <span>Place new order</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'

export default {
    name: 'SuccessModal',
    created() {
    },
    data() {
        return {
            instance: null
        }
    },
    mounted() {
        this.instance = new Modal(document.getElementById('successModal'))
        this.instance.show()
        console.log('success')
    },
    watch: {
        'instance._isShown': {
            deep: true,
            handler(val) {
                if (val === false) {
                    // this.$emit('close')
                }
            }
        }
    }
}
</script>

<style scoped>
.modal-confirm {
    color: #434e65;
    width: 525px;
}

.modal-confirm .modal-content {
    padding: 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
}

.modal-confirm .modal-header {
    background: #47c9a2;
    border-bottom: none;
    position: relative;
    text-align: center;
    margin: -20px -20px 0;
    border-radius: 5px 5px 0 0;
    padding: 35px;
}

.modal-confirm h4 {
    text-align: center;
    font-size: 36px;
    margin: 10px 0;
}

.modal-confirm .form-control,
.modal-confirm .btn {
    min-height: 40px;
    border-radius: 3px;
}

.modal-confirm .close {
    font-size: 24px;
    position: absolute;
    top: 15px;
    right: 15px;
    color: #fff;
    text-shadow: none;
    opacity: 0.5;
    border: unset;
    background: unset;
}

.modal-confirm .close:hover {
    opacity: 0.8;
}

.modal-confirm .icon-box {
    color: #fff;
    width: 95px;
    height: 95px;
    display: inline-block;
    border-radius: 50%;
    z-index: 9;
    border: 5px solid #fff;
    padding: 15px;
    text-align: center;
}

.modal-confirm .icon-box i {
    font-size: 64px;
    margin: -4px 0 0 -4px;
}

.modal-confirm.modal-dialog {
    margin-top: 80px;
}

.modal-confirm .btn,
.modal-confirm .btn:active {
    color: #fff;
    border-radius: 4px;
    background: #eeb711 !important;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border-radius: 30px;
    margin-top: 10px;
    padding: 6px 20px;
    border: none;
}

.modal-confirm .btn:hover,
.modal-confirm .btn:focus {
    background: #eda645 !important;
    outline: none;
}

.modal-confirm .btn span {
    margin: 1px 3px 0;
    float: left;
}

.modal-confirm .btn i {
    margin-left: 1px;
    font-size: 20px;
    float: right;
}

.trigger-btn {
    display: inline-block;
    margin: 100px auto;
}</style>
