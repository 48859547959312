export const apiState = {
  alert: {
    status: false,
    type: 0,
    message: null,
    timeout: null
  },
  stats: null,
  rows: null,
  pagination: {
    lastPage: '',
    currentPage: '',
    total: '',
    lastPageUrl: '',
    nextPageUrl: '',
    prevPageUrl: '',
    from: '',
    to: ''
  },
  apiParams: {
    draw: 0,
    search: '',
    status: null
  },
  searchBox: false
}
