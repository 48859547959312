<template>
    <div class="login-view">
        <div class="body-auth">
            <div class="box-window d-flex justify-content-center align-items-center w-100">
                <div class="">
                    <div class="d-flex justify-content-center mb-4">
                        <img src="@/assets/logo.svg" alt="Sixerr">
                    </div>
                    <div class="d-flex justify-content-center mb-4">
                        <h1>Sign in</h1>
                    </div>

                    <div class="box box-shadow-default">
                        <form @submit.prevent="submitForm">
                            <div v-if="this.error.status" class="form-group mb-4">
                                <div class="alert alert-danger" role="alert">
                                    <strong>Error!</strong> {{ this.error.message }}
                                </div>
                            </div>
                            <div class="form-group mb-4">
                                <label for="email" class="form-label">Email address</label>
                                <input type="email" class="form-control" id="email" aria-describedby="emailHelp"
                                    v-model="input.email">
                            </div>
                            <div class="form-group mb-4">
                                <label for="password" class="form-label">Password</label>
                                <input type="password" class="form-control" id="password" v-model="input.password">
                            </div>
                            <div class="d-flex justify-content-end align-items-center mb-4">
                                <router-link :to="{ name: 'auth.forget' }" class="text-decoration-none forgot-anchor">
                                    Forgot your password?
                                </router-link>
                            </div>
                            <button type="submit" class="btn btn-primary w-100">
                                <span v-show="loading" class="spinner-border spinner-border-sm">
                                </span>
                                <span v-show="!loading">
                                    Login
                                </span>
                            </button>
                        </form>
                    </div>

                    <h5 class="d-flex justify-content-center m-0 mt-3">
                        Dont have an account?
                    </h5>
                    <router-link to="/register" class="d-flex justify-content-center text-decoration-none forgot-anchor">
                        Sign up
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoginVue',
    created() {
    },
    data() {
        return {
            loading: false,
            success: 0,
            // remember: false,
            error: {
                status: false,
                message: null
            },
            input: {
                email: '',
                password: ''
            }
        }
    },
    methods: {
        async submitForm() {
            this.loading = true

            if (this.input.email === '' || this.input.password === '') {
                this.error.status = true
                this.error.message = 'Email address or password can not be empty'
                this.loading = false
            } else if (this.validateEmail(this.input.email)) {
                this.error.status = true
                this.error.message = 'Email address is not valid'
                this.loading = false
            } else {
                this.error.status = false
                this.error.message = null

                await this.$recaptchaLoaded()
                const token = await this.$recaptcha('login')
                console.log('googleReCaptcha Token: ', token)

                this.$store.dispatch('auth/login', this.input).then(
                    (res) => {
                        this.$store.dispatch('fetchSetting', this).then(() => {
                            this.success++
                        })
                    },
                    (error) => {
                        this.error.message = ''

                        if (typeof error.errors !== 'undefined') {
                            const errors = error.errors
                            Object.keys(errors).forEach((errorKey) => {
                                errors[errorKey].forEach((errVal, errIdx) => {
                                    this.error.message += (this.error.message === '') ? errVal : ', ' + errVal
                                })
                            })
                        } else if (typeof error.message !== 'undefined') {
                            this.error.message = error.message
                        }

                        this.error.status = true
                        this.loading = false
                    }
                )
            }
        },
        validateEmail(email) {
            return !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
        }
    },
    watch: {
        success: function (newVal, oldVal) {
            if (newVal >= 1) {
                this.$router.push({ name: 'dashboard.index' })
            }
        }
    }
}
</script>

<style scoped></style>
