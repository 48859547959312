import axios from 'axios'
import authHeader from '@/store/auth.header'
import { apiMutation } from '@/config/apiMutation.config'
import { apiState } from '@/config/apiState.config'

const defaultModule = 'announcement'
const defaultState = {
    loading: {
        createRequests: 1,
        created: 0,
        index: false
    },
    columns: [
        {
            label: 'ID',
            name: 'id'
        },
        {
            label: 'Title',
            name: 'text'
        },
        {
            label: 'Date',
            name: 'date'
        }
    ]
}

export const announcement = {
    namespaced: true,
    state: JSON.parse(JSON.stringify({ ...apiState, ...defaultState })),
    actions: {
        reset({ state }) {
            state.rows = null
            state.loading.created = 0
        },
        created({ state, dispatch, commit }) {
            if (state.rows === null) {
                commit('setRows', [])
            }
            dispatch('index').then(() => {
                commit('incLoadingCreated')
            })
        },
        index({ state, commit }, url = process.env.VUE_APP_API_URL + defaultModule) {
            commit('setLoadingIndex', true)

            return axios
                .get(url, {
                    headers: authHeader()
                })
                .then(res => {
                    commit('setRows', res.data)
                    commit('setLoadingIndex', false)
                    return Promise.resolve(res.data)
                })
                .catch(err => {
                    commit('setLoadingIndex', false)
                    return Promise.reject(err.response.data)
                })
        }
    },
    mutations: { ...apiMutation }
}
