import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/custom.css'
import { createApp } from 'vue'
import helpersPlugin from './plugins/helpersPlugin'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/js/bootstrap.js'

import { VueReCaptcha } from 'vue-recaptcha-v3'

createApp(App)
    .use(helpersPlugin)
    .use(store)
    .use(router)
    .use(VueReCaptcha, {
        siteKey: process.env.VUE_APP_GOOGLE_CAPTCHA_KEY,
        loaderOptions: {
            useRecaptchaNet: true
        }
    }).mount('#app')
