<template>
    <Spinner v-if="loading.render" />
    <div v-else>
        <TitleModule :title="$options.title" />

        <StatsModule v-if="stats" :data="stats" />

        <div class="affiliates-view">
            <div class="row">
                <div class="col-12">
                    <div class="card border-0 mb-3">
                        <div class="card-header card-header-custom bg-transparent border-0">Refer and Earn today!</div>
                        <div class="card-header card-header-sub bg-transparent border-0">Use the link below to refer members
                            to Sixerr and earn a flat <span class="ref-percentage">{{ settings.commission_percentage
                            }}%</span> commission, forever!</div>
                        <div class="card-body pt-4 pb-4">
                            <div class="row">
                                <div class="col-7 col-md-6 col-lg-5 col-xl-3">
                                    <input v-on:focus="$event.target.select()" :value="referralLink" ref="referralLink"
                                        type="text" id="inputReferral" class="form-control" readonly="readonly">
                                </div>
                                <div class="col-7 col-md-6 col-lg-7 col-xl-9">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <a @click="copyReferralLink" class="btn btn-custom btn-order me-3"
                                            data-toggle="popover" data-trigger="focus" title="Dismissible popover"
                                            data-content="And here's some amazing content. It's very engaging. Right?">
                                            Copy Link
                                        </a>
                                        <a v-if="referralLinkCopied">
                                            Copied to clipboard!
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card border-0 mb-3">
                        <div class="card-header card-header-custom bg-transparent border-0">Affiliates History</div>

                        <div class="card-body">
                            <div v-if="loading.index" class="d-flex justify-content-center align-items-center"
                                style="min-height: 431px;">
                                <div class="spinner-border" role="status">
                                </div>
                            </div>
                            <div v-else="rows.length <= 0" class="d-flex justify-content-center align-items-center"
                                style="min-height: 431px;">
                                <h5>No data found</h5>
                            </div>
                            <div v-else>
                                <Datatable :columns="columns">
                                    <tbody>
                                        <tr v-for="(row, idx) in rows" :key="idx">
                                            <td>{{ row.date }}</td>
                                            <td>{{ row.clicks }}</td>
                                            <td>{{ row.registered }}</td>
                                            <td>{{ $centsToDollar(row.commission) }}</td>
                                            <td>
                                                <StatusButton :status="row.status" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Datatable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StatusButton from '@/components/StatusButton'
import TitleModule from '@/components/Modules/TitleModule'
import StatsModule from '@/components/Modules/StatsModule'
import Datatable from '@/components/Form/Datatable'
import Spinner from '@/components/Plugin/Spinner.vue'
import axios from 'axios'
export default {
    title: 'Affiliates',
    name: 'AffiliatesView',
    components: { Datatable, StatsModule, TitleModule, StatusButton, Spinner },
    created() {
        this.index()
    },
    data() {
        return {
            loading: {
                render: true,
                index: false
            },
            columns: [
                {
                    label: 'Month',
                    name: 'month'
                },
                {
                    label: 'Clicks',
                    name: 'clicks'
                },
                {
                    label: 'Registered',
                    name: 'registered'
                },
                {
                    label: 'Commission',
                    name: 'commission'
                },
                {
                    width: '80px',
                    label: 'Status',
                    name: 'status'
                }
            ],
            stats: null,
            rows: null,
            referralLinkCopied: false
        }
    },
    methods: {
        async index(url = process.env.VUE_APP_API_URL + 'v1/user/affiliate') {
            this.loading.index = true

            try {
                const res = await axios.get(url, {
                    headers: this.$authHeader()
                })

                this.rows = res.data.data

                this.stats = [
                    {
                        id: 1,
                        title: 'Users',
                        slug: 'registered',
                        value: res.data.meta.stats.registered,
                        info: 'total signed up',
                        svg: 'affiliateUsers'
                    },
                    {
                        id: null,
                        title: 'Commission',
                        slug: 'commission',
                        value: this.$centsToDollar(res.data.meta.stats.commission),
                        info: 'total earned',
                        svg: 'affiliateCommission'
                    },
                    {
                        id: null,
                        title: 'Withdrawn',
                        slug: 'revenue',
                        value: this.$centsToDollar(res.data.meta.stats.revenue),
                        info: 'total revenue',
                        svg: 'affiliateWithdrawn'
                    }
                ]

                this.loading.index = false
                this.loading.render = false
            } catch (e) {
                this.loading.index = false

                throw new Error(e)
            }
        },

        copyReferralLink() {
            this.$refs.referralLink.focus()
            document.execCommand('copy')
            this.referralLinkCopied = true
        },
    },
    computed: {
        settings() { return this.$store.state.settings },
        referralLink() {
            return window.location.origin + this.$router.resolve({ name: 'register' }).href + '/' + this.$store.state.user.id
        }
    }
}
</script>

<style scoped>
.ref-percentage {
    font-size: 20px;
    font-weight: bold;
    color: var(--green-default);
}
</style>
